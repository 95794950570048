.toolbar_header {
  margin: 4vh 0;

  @media #{$medium-up} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.toolbar_header__title {
  color: $darkgrey;
  font-size: 3em;
  @include pt-sans-narrow;
  @include fontsmoothing;
  margin-bottom: 0;
}

.toolbar_header__tools {
  display: flex;
  align-items: center;

  > * { 
    display: inline-block;
    margin-right: 8px; 
  }

  .toolbar_action_title {
    @include fontsmoothing;
    margin-right: 16px;
  }

  .toolbar_icons {
    display: flex;
    align-items: center;

    button {
      appearance: none;
      padding: 0;
      margin-right: 8px;
    }

    .active {
      outline: none;

      .icon {
        color: $blue;
      }
    } 

    .icon { 
      color: $slate;
      font-size: 18px;
    }

    
  }

  .toolbar_icons + .toolbar__text_link {
    border-left: 1px solid $grey;
    margin-left: 8px;
    padding-left: 16px;
  }

  .toolbar__text_link {
    @include fontsmoothing;
    color: $gold;
  }
}