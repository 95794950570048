//
// Basic modal styles

.site_wrapper .v--modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index:9999;
}

// The modal close button
.modal__close_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  cursor: pointer;

  svg {
    color: white;
    width: 1.5rem;
    height: 1.5rem;
  }
}


// let next and previous buttons be visible
.v--modal-overlay .v--modal-box { overflow: visible !important; }

.v--modal-box .modal-container {
  position: relative;
  background-color: $blue;
  color: white;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  @media #{$large-up} {
    height: auto;
    width: 66%;
    max-width: 768px;
  }
  > div {
    padding: 2em;
    @media #{$medium-up} {
      padding: 2.5em;
    }
    @media #{$large-up} {
      padding: 3.5em;
    }
    > button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 1em 1em 0 0;
      color: white;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .btn {
      margin-top: 0.5em;
    }
    .btn--icon {
      display: inline-flex;
    }
    .btn--white_outline:hover {
      border-color: white;
      color: white;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }
}

//
// basic modal box
.site_wrapper .v--modal-box {
  position:relative;
}

.video-container {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}