//
// Basic Page Styles
//

.dark-bg {
    background-image: linear-gradient(-180deg, #414141 1%, #000000 100%);
}

.basic-background-image {
    position: fixed;
    height: auto;
    width: 100%;
    top: 0;

    @media #{$large-up} {
        max-height: 100vh;
        width: auto;
    }
}

.basic {
    margin: 0 3% 300px;
    padding: 2% 6%;
    position: relative;
    top: 200px;
    z-index: 100;
    background-color: $ltgrey;
    max-width: 900px;

    @media #{$large-up} {
        width: 100%;
        top: 50px;
        margin-bottom: 100px;
        float: right;
    }

    .breadcrumbs {
        margin-bottom: 4vh;
    }

    .introductionText {
        @extend .introductionText--gold;
    }

    .feature-row-wrap .feature-row .image-block {
        height: 30vh;
    }
}