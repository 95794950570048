.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-wineglass {
  width: 0.5908203125em;
}

.icon-plus {
  width: 0.7857142857142857em;
}

.icon-minus {
  width: 0.7857142857142857em;
}

.icon-th-large {
  width: 0.9285714285714285em;
}

.icon-clock-o {
  width: 0.8571428571428571em;
}

.icon-road {
  width: 1.0714285714285714em;
}

.icon-tag {
  width: 0.8454285714285714em;
}

.icon-map-marker {
  width: 0.5714285714285714em;
}

.icon-question-circle {
  width: 0.8571428571428571em;
}

.icon-calendar {
  width: 0.9285714285714285em;
}

.icon-twitter {
  width: 0.9285714285714285em;
}

.icon-facebook {
  width: 0.5881428571428571em;
}

.icon-arrow-circle-left {
  width: 0.8571428571428571em;
}

.icon-arrow-circle-right {
  width: 0.8571428571428571em;
}

.icon-arrow-circle-up {
  width: 0.8571428571428571em;
}

.icon-arrow-circle-down {
  width: 0.8571428571428571em;
}

.icon-arrows-alt {
  width: 0.8571428571428571em;
}

.icon-pinterest {
  width: 0.8571428571428571em;
}

.icon-coffee {
  width: 1.0357142857142856em;
}

.icon-angle-left {
  width: 0.375em;
}

.icon-angle-right {
  width: 0.33928571428571425em;
}

.icon-angle-up {
  width: 0.6428571428571428em;
}

.icon-angle-down {
  width: 0.6428571428571428em;
}

.icon-instagram {
  width: 0.8571428571428571em;
}

.icon-bus {
  width: 0.8571428571428571em;
}

.icon-play {
  width: 0.8571428571428571em;
}

.icon-close {
  width: 0.8571428571428571em;
}
