//
// Home Page Styles
//

//
// Event Announcement
.event_announcement {
  background-color: darken($blue, 32%);
  box-shadow: inset 0 0 80px darken($darkgrey, 8%);
  color: #ccc;

  .container {
    padding: 4vh 3vw 3vh;
    transition: opacity 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    max-width:1200px;
  }

  .welcome_text {
    @include pt-sans-narrow;
    text-transform: uppercase;
    color: $gold;
    font-weight: 500;
    text-align: center;
    font-size:2em;

  }

  .welcome_smalltext {
    text-align:center;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.3em;
    font-size: 0.9em;
  }

  .flex_wrapper {
    @media #{$medium-up} {
      display: flex;
    }
  }

  .events_listings__event {
    align-self: flex-start;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);

    @media #{$medium-up} {
      width: 33%;
      margin-right: 2%;
    }

    @media #{$large-up} {
      width: 25%;
    }
  }

  .events_listings__schedule_specials {
    @media #{$medium-up} {
      flex: 2;
      margin-right: 2%;
    }
    a {
      margin-top: 5px;
      display: inline-block;
      @include pt-sans-narrow;
      text-transform: uppercase;
      color: $gold;
    }
  }

  .events_listings__details {
    margin: 0;
    padding: 0;

    @media #{$medium-up} {
      flex: 3;
    }

    .lawn_seating {
      display: flex;

      > div { flex: 1; }
      > .image {
        max-width: 250px;
        margin-left: 0.5em;
      }
      > .image img {
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
      }
    }

    li { list-style-type: none; }

    a {
      margin-top: 5px;
      display: inline-block;
      @include pt-sans-narrow;
      text-transform: uppercase;
      color: $gold;
    }
  }
  .close {
    color: #fff;
    display: inline-block;
    position:absolute;
    right:3vw;
    top:2vh;
    svg {
      color:#fff;
      display: inline-block;
      font-size:24px;
    }
  }
}

.slideIn-enter-active,
.slideIn-leave-active {
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0.250, 0.460, 0.450, 0.940);

  .container { opacity: 0; }
}

.slideIn-enter-to, .slideIn-leave {
  max-height: 1000px;

  @media #{$small-only} {
    max-height: 1500px
  }

  .container { opacity: 1; }
}

.slideIn-leave-to,
.slideIn-enter {
  max-height: 0;

  .container { opacity: 0; }
}



.container.home {
  max-width: 1440px;
}

//
// HOME FEATURES
//
.feature-home {
  &.fancy-feature h3 {
    font-size: 1.75rem;
  }
}


//
// WALLOP SLIDER
//
.home_slider {

  .Wallop-list {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 50vh;
    min-height: 300px;

    .Wallop-item {
      right: 0;
      bottom: 0;
      width: 100%;
      align-self: stretch;
      display: flex;
      align-items: flex-end;
      background-size: cover;
      background-position: center center;

      .events_listings__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .events_listings__title,
        .events_listings__pre_title,
        .events_listings__post_title {
          margin: 0;
        }

        .events_listing__datetime {
          margin: 0;
          color: $gold;
          @include pt-sans-narrow;
        }

        &.offseason {
          padding: 18px;
          
          .events_listings__title {
            text-align: center;
            margin: 0 auto;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.25rem;
            @include fontsmoothing;
          }
          
          @media #{$medium-up} {
            padding: 40px;
            
            .events_listings__title {
                font-size: 1.4375em;
              }
          }
        }
      }

      .bookingLink {
        @extend .btn, .btn--medium;
        margin: 5px;
      }

      .bookingLink:not(:first-child) {
        @extend .btn--outline, .btn--white_outline;
      }
    }

    .events_listings__content {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  // Pagination Selector Section
  .Wallop-pagination {
    background-color: $darkgrey;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow-x: scroll;
    overflow-y: hidden;

    .Wallop-dot {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      width: 100%;
      min-width: 150px;
      padding: 0;
      background-color: transparent;
      transition: background-color 0.3s ease;
      outline: 0;
      border-right: 1px solid $medgrey;
      &--current {
        background-color: #000;
      }
    }

    .events_listings__img {
      h3 {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -1px;
        padding:0 10px;
        height: 134px;
        display: flex;
        align-items: center;    
        overflow: hidden;
        margin-bottom: 0; 
      }
    }

    .events_listings__img img {
      width: 100%;
    }

    .content {
      width: 100%;
      padding: 1vh;
      text-align: left;
      align-self: center;
      h4 {
        @include crimson-text;
        font-size: em-calc(18px);
        font-weight: 500;
        color: $gold;
        margin-bottom: 4px;
      }
      h2 {
        @include pt-sans-narrow;
        font-weight: 500;
        font-size: em-calc(16px);
        color: #fff;
      }
    }
  }

  // Medium Media Query
  @media #{$medium-up} {
    .Wallop-list {
      height: 50vh;
      min-height: 500px;
    }
  }

  // Large Media Query
  @media #{$large-up} {
    display: flex;
    flex-direction: row;
    height: 50vw;
    max-height: 800px;

    .Wallop-list {
      width: 72%;
      height: 100%;
      min-height: auto;

      .events_listings__title {
        font-size: em-calc(40px);
      }
      .events_listing__datetime {
        font-size: em-calc(20px);
      }
    }
    .Wallop-pagination {
      width: 28%;
      display: block;
      overflow-x: hidden;
      overflow-y: scroll;

      .Wallop-dot {
        border-right: 0;
        border-bottom: 1px solid $medgrey;
        max-height: 200px;

        >div {
          display: flex;
          flex-direction: row;
        }
        .content {
          width: 55%;
        }
        .events_listings__img {
          width: 50%;
          display:flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}


.concertNews {
  margin: 7vh 0;

  @media #{$medium-up} {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    gap: 14px;
    padding: 0 10px;
  }

  .newsItem {
    width: 100%;
    overflow: hidden;
    background-color: $blue;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media #{$medium-up} {
      flex-basis: 440px;
    }

    .play-btn {
      position: absolute;
      z-index: 3;
      width:80px;
      height:200px;
      left:50%;
      margin-left: -40px;
      color:white;

      svg {
        width: 100%;
        height:200px;
        margin:0 auto;
        text-align: center;
        filter: drop-shadow(0 0 5px rgba(0,0,0,0.6))
      }
    }
  }

  a, .image-block {
    display: block;
    width: 100%;
    height: 100%;
  }

  a {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .image-block {
    position: absolute;
    top: 0;
    right: 0;
    transition: all .5s;
}

  .content {
    flex: 1;
    position: relative;
    padding: 1vh 2vh;
    bottom: 0;
    z-index: 101;
    margin-top: 200px;
    background-color: rgba(0,0,0,0.4);
    transition: background-color .5s;
    min-height: 130px;

    h6, h3 { color: #fff; }
    h6 {
      margin-bottom: 0;
      @include pt-sans;
      @include fontsmoothing;
      font-style: italic;
      font-weight: 100;
      font-size: em-calc(18);
    }
    h3 {
      font-size: em-calc(34);
      margin-bottom: 0;
      text-transform: none;
    }
  }

  // Hover

  a:hover,
  a:focus {
    cursor: pointer;

    .image-block { transform: scale(1.1);}
    .content { background-color: rgba(170,145,87,0.9); }
  }

}

//
// Recent News Block
//

.home-press-row {
  width: calc(100% - 10px);
  margin: 5px;
  background-color: #fff;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media #{$small-only} {
    margin-top: 4vh;
  }

  @media #{$medium-up} {
    width: calc(50% - 10px);
  }

  @media #{$large-up} {
    width: 50%;
    margin: 0 10px;
  }

  .title-bar p {
    width: 50%;
    float: left;
    font-weight: 700;

    &:last-child { text-align: right;}
  }

  .content {
    padding: 3vh 4vh 1.5vh;
    text-align: center;

    h2 a {
      font-size: em-calc(22);
      letter-spacing: -0.01em;
      line-height: 1.2em;
      color: $gold;
      @include pt-sans-narrow;
      font-weight: 100;
    }

    p {
      font-size: em-calc(16);
      @include crimson-text;
      font-weight: 400;
      margin-bottom: 0.5em;
    }
    b {
      font-weight: 400;
    }
  }

  .link {
    margin-bottom: 3vh;
    margin-top: auto;
    text-align: center;

    a { display: inline-block; }
    a svg { margin-top: -5px; }
  }
}
