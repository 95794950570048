/**
 * wallop.css
 *
 * @fileoverview Default styles for wallop – recommended
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

.Wallop { position: relative; }

.Wallop-list {
  position: relative;
  overflow: hidden;
}

.Wallop-item {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.Wallop-item--current {
  visibility: visible;
  position: relative;
}

/**
 * wallop--slide.css
 *
 */

/* the 10ms animation-delay fixed some weird rendering issues with iPhone */
.Wallop--slide {
  .Wallop-item--showPrevious { animation: slideFromLeft 350ms 10ms linear both; }
  .Wallop-item--showNext { animation: slideFromRight 350ms 10ms linear both; }
  .Wallop-item--hidePrevious, .Wallop-item--hideNext { visibility: visible; }
  .Wallop-item--hidePrevious { animation: slideToLeft 350ms 10ms linear both; }
  .Wallop-item--hideNext { animation: slideToRight 350ms 10ms linear both; }
}

@keyframes slideFromLeft {
  0% { transform: translate3d(-100%, 0, 0); }
}
@keyframes slideFromRight {
  0% { transform: translate3d(100%, 0, 0); }
}

@keyframes slideToLeft {
  99% { opacity: 1; }

  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideToRight {
  99% { opacity: 1; }

  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
