//
// Banner styles

.page_banner--event {
  color: #fff;
  flex-direction: column;
  flex-wrap: wrap;

  @media only screen and (min-width: em-calc(850)) {
    flex-direction: row;
  }

  // switch layout later for event banners
  .page_banner__content,
  .page_banner__image {
    width: 100%;

    @media only screen and (min-width: em-calc(850)) {
      width: 50%;
    }
  }

  .page_banner__content {
    display: flex;
    flex-direction: column;
    padding: 0;

    // tweak where padding applies
    > div:not(.event_banner__footer, .event_banner__date) {
      padding: 0 60px;
      margin-bottom: 60px;
    }
    > div.event_banner__footer {
      padding:20px 30px;
      margin-bottom: 0;
    }
    > div.event_banner__date {
      padding-top:40px;
      padding-bottom:20px;
      margin-bottom: 0;
    }
  }
}

.no-phones-banner {
  width: 100%;
  padding: 30px;
  background-color: $blue;
  color: white;
  font-size: 1.2em;
  a {
    color: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 10px;
  }
  .icon { 
    width: 24px;
    height: 24px;
  }
}

#no-phones {
  padding-top: 1em;
  padding-bottom: 1em;
  .no-phones-lead {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;
    gap: 10px;
    .icon {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
    }
  }
  .no-phones-lead::before, .no-phones-lead::after {
    content: "";
    border-top: 1px solid #A6A6A6;
    height: 1px;
    width: 100%;
  }
}


.event_banner__date {
  @include pt-sans-narrow;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: auto; // align content below to the middle
  padding: 30px 30px 60px;

  p {
    @include fontsmoothing;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    display: flex;
    align-items: center;

    &:first-of-type {
      font-size: em-calc(32);
      font-weight: bold;
      margin-bottom: 8px;
    }

    &:last-of-type {
      font-size: em-calc(23);
    }

    .icon {
      font-size: 18px;
      color: $grey;
      margin-right: 10px;
    }
  }

  // add to calendar link
  a {
    color: $gold;
    font-size: em-calc(18);
    padding: 10px 0;
  }
}

.event_banner__copy {
  padding: 0 30px;
}

.event_banner__pre_title, .event_banner__post_title {
  color: $grey;
  font-style: italic;
  font-size: em-calc(18);
  margin-bottom: 0;
}

.event_banner__post_title {
  color:#fff;
  font-style:normal;
}

.event_banner__title {
  @include pt-sans-narrow;
  color: $blue;
  font-size: em-calc(52);
  line-height: 1;
  margin-bottom: em-calc(10px, 52);
}

.event_banner__doors {
  color: $grey;
}

.event_banner__footer {
  margin-top: auto; // align to bottom
  background-color: $darkgrey;
  color: #fff;
  padding: 20px 30px;
  font-size: em-calc(18);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .icon {
    font-size: 26px;
    margin-right: 8px;
    color: $grey;
  }

  // the prices
   > p {
    @include fontsmoothing;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.2;
    margin-right: auto;

    span {
      display: block;
      font-style: italic;
      font-weight: 400;
    }
  }

  // ticket button wrapper, can contain a couple states
  .event_banner__ticket_btn {
    margin: 1em 0 1em 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;

    .btn { 
      @extend .btn, .btn--medium;
      display: block;
    }

    .parking-btn { 
      @extend .btn--outline, .btn--white_outline;
    }

    p {
      @include fontsmoothing;
      margin-bottom: 0;
      font-style: italic;
      font-size: em-calc(14px);
      font-weight: normal;
    }

    .btn + p {
      margin-top: 0.5em;
    }
  }

}
