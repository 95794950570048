//
// Accordion Styles
.accordion-menu input[type=checkbox] {
	/* hide native checkbox */
	position: absolute;
	opacity: 0;
}

.accordion-menu {
  padding: 0;
  margin: 0 0 5vh;
  background-color: #fff;
  border: 1px solid darken($ltgrey, 4%);
}

.accordion-menu > li {
	position: relative;
	list-style-type: none;

	& > ul {
		/* by default hide all sub menus */
		display: none;
  	padding: 0;
  	margin: 0;

		& > li {
			position: relative;
			list-style-type: none;
		}

	}

}

.accordion-menu label {

  .title-wrapper {
    border-bottom: 1px solid darken($ltgrey, 4%);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  h4 {
    display: inline;
    padding: 10px 0 10px 20px;
    color: $blue;
    font-size: em-calc(24px);
    @include fontsmoothing;
    font-weight: 500;
    margin: 0;
  }

  .arrow-icon {
    font-size: 20px;
    padding: 10px 25px;
    background-color: #FBF9F6;
  }

  svg {
    width: 10px;
    transform: rotate(90deg);
    transition: transform 0.3s cubic-bezier(.21, 0, .36, 1);
  }
}

.accordion-menu input[type=checkbox]:checked + label + ul,
.accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
	/* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
	/* show children when item is checked */
	display: block;
}

.accordion-menu input[type=checkbox]:checked + label div svg {
  transform: rotate(270deg);
}

.accordion-menu .answer {
  padding: 3vh;
  border-bottom: 1px solid darken($ltgrey, 4%);

	// Thumbs
	.thumb-wrapper {

	}
	img {
	  display: inline-block;
	  padding: 0;
	  margin: 0 0 1em;
	  border:5px solid white!important;
	  box-shadow:0 0 5px rgba(0,0,0,.2);
	  width:47%;
	  vertical-align:middle;
	}
	img:nth-child(odd) {
	  margin-right:2%;
	}
}
