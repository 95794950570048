//
// The events list
.events_listings {
  @include unstyle-list;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7vh;
  justify-content: space-between;

  // Floats last element to the left instead of leaving a space
  &:after {
    content: "";
    flex: auto;
  }
}

//
// A single event in the list
.events_listings__event {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 3vh;
  background-color: #000;
  display: flex;
  flex-direction: column;

  @media #{$medium-up} {
    width: 48%;

    // Takes care of gutters
    &:nth-child(2n-1) { margin-right: 4%; }
  }

  @media #{$large-up} {
    width: 31.5%;

    // Takes care of gutters
    &:nth-child(2n-1) { margin-right: auto; }
    &:nth-child(3n-1) {
      margin-left: 2.75%;
      margin-right: 2.75%;
    }
  }

  > a { text-decoration: none; }

  &:hover {
    .events_listings__img { transform: scale(1.1); }
    .events_listings__date { color: lighten($gold, 10%); }
  }
}

//
// The event image
.events_listings__img {
  width: 100%;
  transition: all .5s;
  backface-visibility: hidden;
}

//
// Event content wrapper
.events_listings__content {
  background-color: $black;
  overflow: hidden;
  width: 100%;
  padding: 10px 25px 20px;
  position: relative;
  z-index: 100;
  flex: 1;
}

.events_listings__date {
  @include crimson-text;
  color: $gold;
  font-size: em-calc(34);
  margin: 0 0 16px -10px;
  line-height: 1;
  transition: color .3s;
}

.events_listings__pre_title, .events_listings__post_title {
  color: $grey;
  font-size: em-calc(14);
  font-style: italic;
  margin: 0 64px 0 0;
  line-height:1.3;
}

.events_listings__post_title {
  font-style:normal;
}

.events_listings__title {
  @include pt-sans-narrow;
  font-size: em-calc(23);
  color: #fff;
  margin: 0 64px 0 0;
  font-weight: normal;
}

.events_listings__ticket_link {
  position: absolute;
  bottom: -64px;
  right: -64px;
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background-color: rgba($gold, 0.7);
  transition: background-color 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);

  &:hover {
    background-color: $gold;
  }

  .icon {
    font-size: 32px;
    color: #fff;
    position: absolute;
    left: 21px;
    top: 21px;
  }
}


//
// Event Calendar
.vr_calendar {
  border: 1px solid lighten($grey, 20%);

  .title {
    @include pt-sans-narrow;
    text-transform: uppercase;
    color: $gold;
  }

  .week { color: $gold; }

  .next-month, .prev-month {
    color: $blue;
  }
}
