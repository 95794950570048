//
// Today's Event
//

.todaysevent__body {
  padding-bottom:2em;

  @media #{$large-up} {
    display:flex;
  }
  .todaysevent__column {
    @media #{$large-up} {
      width: 49%;
    }
  }
  .todaysevent__column:last-child {
    @media #{$large-up} {
      padding-left:2%;
    }
  }

}

.todaysevent__body-heading {
  font-size:2.5em;
}

.todaysevent__banner.no-event {
  padding-top:6em;
  padding-bottom:6em;
  text-align:center;
  width:100%;
  h1 {
    font-size:4em;
  }
  p {
    font-size:1.5em;
  }
  a {
    color: $gold;
  }
}

.todaysevent__banner .events_listings {
  padding:0 2em 0;
  margin-bottom:0;

  .events_listings__event {
    margin-left:auto;
    margin-right:auto;
    width:90%;

    @media #{$medium-up} {
      margin-left:1%!important;
      margin-right:1%!important;
      width:48%;
    }

    @media #{$large-up} {
      margin-left:1%!important;
      margin-right:1%!important;
      width:23%;
    }

    h2, p {
      text-align:left;
    }
    .events_listings__post_title, .events_listings__pre_title {
      font-size:.85em;
    }
  }
}

.event_announcement .events_listings__schedule_specials {
  .no-phones-notice {
    padding-top: 1em;
    color: white;
    text-transform: none;
    a {
      & > .icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }
      display: flex;
      align-items: center;
      color: white;
      text-transform: none;
      gap: 10px;
    }
  }
}

// Map Thumbs for us in various spots
.depart-maps img {
  display: inline-block;
  padding: 0;
  margin: 0 0 1em;
  border:5px solid white!important;
  box-shadow:0 0 5px rgba(0,0,0,.2);
  width:47%;
  vertical-align:middle;
}
.depart-maps img:first-child {
  margin-right:2%;
}
