//
// Footer Styles
//


footer {
    padding: 50px 0;
    background-color: $darkgrey;
    position: relative;
    z-index: 100;

    .container {
        @include clearfix;
    }
    .tree {
        padding-right: 14px;
        svg {
          width: 110px;
          display: block;
          margin: 0 auto 20px;
        }
    }

    .address {
        text-align: center;
        color: #CECECE;
        overflow-wrap: break-word;
        word-wrap: break-word;

        h3, h4 {
            @include pt-sans-narrow;
            @include fontsmoothing;
            color: #fff;
            text-transform: uppercase;
        }
        h4 {
          font-weight: 400;
        }

        a { color: #fff; }
        p { line-height: 1.3em; }
    }

    .links {
        width: 100%;
        float: right;
        text-align: center;
        margin-top: 10px;

        a {
            margin-left: 15px;
            @include pt-sans-narrow;
            text-transform: uppercase;
            font-size: em-calc(20);
            line-height: 1.5em;
            color: $gold;

            &:hover { color: darken($gold, 10%); }
        }
    }

    .copyright {
        @include crimson-text;
        color: #838383;
        margin-top: 20px;
        line-height: 2em;

        a {
            font-size: em-calc(14);
            color: #838383;
        }

        img {
            height: 25px;
            position: relative;
            top: -4px;
        }
    }

    @media #{$large-up} {
        .tree, .address {
            width: auto;
            float: left;
        }

        .address {
            text-align: left;
        }

        .links {
            width: auto;
            text-align: right;
        }
    }
}