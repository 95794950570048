//
// Animations used by Vue transtions


//
// Fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


//
// Fade down
.fade_down-enter-active,
.fade_down-leave-active {
  transition:
    opacity 0.3s cubic-bezier(0.21, 0, 0.36, 1),
    transform 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.fade_down-enter,
.fade_down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}