//
// Header Styles
//

.preheader {
  background:$darkgrey;
  padding:1em 2em;
  text-align:center;
  position:relative;
  z-index:101;

  &__wrap {
    @media #{$medium-up} {
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
  &__copy {
    color:$warning_orange;
    flex-basis:2;
    padding:0.15em 0.5em;
  }
  &__action {
    padding:0 0.5em 0;
  }

}

.site_header {
  background-color: #fff;
  padding-bottom: 130px;
  position: relative;
  z-index: 101;

  @media #{$medium-up} {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  // Logo
  .header-home {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 280px;

    @media #{$medium-up} {
      height: auto;
      float: left;
      margin: 0 auto;
    }

    svg {
      max-height: 43px;
      position: absolute;
      top: 60px;

      @media #{$medium-up} {
        width: 280px;
        position: static;
      }
    }
  }

  // Main Nav Styles
  .main-nav {
    width: 100%;
    position: absolute;
    top: 130px;
    background-color: #fff;
    @include clearfix;
    z-index: 100;

    @media #{$medium-up} {
      position: static;
      width: auto;
    }

    @media #{$medium-only} {
      // order: 4;
      width: 100%;
      text-align: center;
    }

    @media #{$large-up} {
      flex: 1;
    }

    input, label { display: none; }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;

      li {
        display: inline-block;
        padding: 0 1.5%;
        @media #{$xlarge-up} {
          padding: 0 3%;
        }
        &:nth-child(2) {
          padding-left:0;
        }
        &:last-child {
          padding-right:0;
        }

        @media #{$medium-only} {
          padding: 10px;
        }

        &.mobile-only { display: none; }
      }

      li:hover > ul { display: inherit; }

      // Dropdowns
      ul {
        display: none;
        position: absolute;
        text-align: left;

        @media #{$medium-up} {
          padding-top: 10px;
          width: 86%;
          left: 7%;

          > div {
            padding: 4%;
            background-color: #fff;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.29);
            @include clearfix;
          }

          li {
            float: left;
            clear: left;
            width: 27%;
            padding: 10px 0;
            margin: 0;
            border-bottom: 1px solid $medgrey;
            display: list-item;

            &:last-child { border-bottom: 0; }

            a { font-weight: 700; }
            a:hover { color: $gold; }
          }
        }

        @media #{$large-up} {
          padding-top: 30px;
          width: 60%;
          left: 20%;
        }

        .childExtendedWrapper {
            width: 68%;
            float: right;
            display: flex;
        }

        li.extended {
          display: none;
          float: right;
          width: 100%;
          border-bottom: 0;

          @media #{$medium-up} {
            display: block;
          }

          .bodyImageWrapper {
            display: flex;

            p {
              margin-bottom: 2px;
              line-height: 1.4em;
              font-size: 0.9em;
            }
          }

          .imageWrapper {
            min-width: 40%;
            padding-right: 10px;

            img { width: 100%; height: auto; }
          }

          .links { margin-top: 10px; }
          .links p { margin: 0; }

        }
      }

      // Link Styles
      a {
        @include pt-sans-narrow;
        text-transform: uppercase;
        color: $darkgrey;

        .icon-wrap {
          display:none;
          width:24px;
          text-align:center;
          @media #{$medium-up} {
            display:inline-block;
          }
        }

        .icon {
          font-size: 20px;
          height: 14px;
          margin-right: 5px;
        }
      }

      .arrow-icon {
        display: none;
      }

      @media #{$large-up} {
        .arrow-icon {
          display: none;
          position: relative;
          color: $blue;
          width: 100%;

          svg {
            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
            margin: 0 auto;
            font-size: 2em;
            height: 36px;
          }
        }

        // Down Arrow
        li:hover .arrow-icon {
          display: block;
        }
      }
    }


    @media #{$small-only} {
      .toggle + a, .menu { display: none; width: 100%; }

      .toggle {
        display: block;
        text-transform: uppercase;
        @include pt-sans-narrow;
      }

      .box-shadow-menu {
        padding: 10px;
        position: relative;
        color: #fff;
        background-color: $blue;
        font-size: 1.4em;
      }

      .box-shadow-menu:after {
        content: "";
        position: absolute;
        right: 2%;
        top: 0.6em;
        width: 1em;
        height: 0.1em;
        background: #fff;
        box-shadow:
            0 0.27em 0 0 #fff,
            0 0.53em 0 0 #fff;
      }

      [id^=drop]:checked + ul { display: block; }

      .mobile-only { display: block; }

      > ul > li {
        display: block;
        width: 100%;
        border-bottom: 1px solid $ltgrey;
        padding: 10px;
        margin: 0;
      }

      ul ul {
        float: none;
        position: static;
        margin-top: 10px;
      }

      ul ul li:hover > ul,
      ul li:hover > ul { display: none; }

      ul ul li {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0;
      }

      ul ul li a {
        color: $blue;
        padding: 5px;
        display: block;
      }
      ul ul li a svg { display: none; }

      li label .arrow-icon {
        display: inline;
        margin-left: 5px;

        svg {
          height: 24px;
          top: -3px;
        }
      }

      // Down Arrow
      li:hover label .arrow-icon {
        display: inline;
      }
    }
  }


  // Social Icon Links
  .social {
    display: flex;
    align-items: center;
    min-width: 220px;
    height: 40px;

    @media #{$medium-only} {
      order: -1;
      width: 100%;
      margin-bottom: 1em;
    }

    @media #{$large-up} {
      height: auto;
    }

    .social-icons {
      width: 50%;
      float: left;
      text-align: center;
      margin: 0;
      padding: 0;

      a {
        color: $slate;
        transition: opacity 0.2s cubic-bezier(.21, 0, .36, 1);
      }

      a:hover { opacity: 0.8; }

      .icon { font-size: 20px; }
    }

    li {
      display: inline-block;
      list-style-type: none;
    }

    a {
      width: 20px;
      margin: 0 2px;
      display: block;
    }

  }

  .mailing-list {
    width: 50%;
    height: 40px;
    padding-top: 11px;
    float: left;
    text-align: center;
    background-color: $gold;
    transition: background-color 0.2s cubic-bezier(.21, 0, .36, 1);

    &:hover {
      background-color: saturate($gold, 10%);
    }

    .icon {
      font-size: 18px;
      margin: 0 5px 0 0;
      vertical-align: text-top;
    }

    a {
      padding: 0;
      margin: 0;
      width: 100%;
      @include pt-sans-narrow;
      @include fontsmoothing;
      text-transform: uppercase;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }

    @media #{$large-up} {
      height: auto;
      padding-top: 0;

      .icon {
        display: block;
        margin: 0 auto 7px;
      }

      a { padding: 11% 2%; }
    }
  }
}

.sponsor-banner {
  position: relative;
  background-color: $black;
  z-index: 999;
  a {
    display: block;
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-width: 1400px;
  }
  .sponsor-banner__image {
    display: none;
  }
  @media #{$medium-up} {
    .sponsor-banner__image {
      display: block;
    }
    .sponsor-banner__mobile-image {
      display: none;
    }
  }
  @media #{$xlarge-up} {
    padding: 20px;
  }
}
