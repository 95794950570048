//
//
.pagination {
  margin-top: 3vh;
  padding-top: 3vh;
  padding-bottom: 2vh;
  border-top: 1px solid #ddd;
  text-align: center;

  .pagination_numbers { display: inline-block; }
  a { color: $gold; }
}