//
// Layout Defaults
//

// Set box-sizing globally to handle padding and border widths
*,
*:before,
*:after {
  box-sizing: border-box;
}

// Grid Defaults to get images and embeds to work properly
img,
object,
embed { max-width: 100%; height: auto; }

object,
embed { height: 100%; }
img { -ms-interpolation-mode: bicubic; }

// Get rid of gap under images by making them display: inline-block; by default
img { display: inline-block; }

// hide certain vue components
[v-cloak] { display: none; }

body {
  padding: 0;
  margin: 0;
  background-color: $ltgrey;
}

// A site wide wrapping div
.site_wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
    @include clearfix;
    flex: 1;
}

.container {
  max-width: 1400px;
  padding: 0 4%;
  margin: 0 auto;

  @media #{$large-up} {
    padding: 0 2%;
  }
}

.introductionText,
.entryBody, .mapWrapper, .textBlock, .healthAlert {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.healthAlert {
    background-color: #fff;
    border: 1px solid #A6A6A6;
    margin-bottom: 55px;
    padding: 30px 30px 10px;
}

// Breadcrumbs
.breadcrumbs {
    color: #A6A6A6;
    @include pt-sans-narrow;

    a { color: $gold; }
}

//
// Article footer navigation
.article_footer_nav {
  width: 100%;
  margin-top: 7vh;

  @media #{$medium-up} {
    display: flex;
    flex-wrap: wrap;
  }

  .btn {
    flex: 1;
    font-size: em-calc(26px);
    padding: 18px 20px;
  }

  .icon {
    font-size: 26px;
    width: 46px; // make sure icon doesn't shrink, includes margin
  }
}
