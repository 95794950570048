/*******************************************************************
 * Type Defaults
 *******************************************************************/

// Use these to include the font into classes
@mixin crimson-text  { font-family: 'Crimson Text', serif; }
@mixin pt-sans-narrow { font-family: 'PT Sans Narrow', sans-serif; }
@mixin pt-sans { font-family: 'PT Sans', sans-serif; }


// Font aintaliasing. Includes webkit and firefox version to be included in a future version */
@mixin fontsmoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body { font-size: $base-font-size; }

// Default body styles
body {
  @include pt-sans;
  color: #444;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
}

// i tag is used to denote icons, em for italics
i { @include fontsmoothing; font-style: normal; }

// Override outline from normalize, we don't like it
a {
  transition: color 0.2s cubic-bezier(.21, 0, .36, 1),
  background-color 0.2s cubic-bezier(.21, 0, .36, 1);
  color: $blue;
  text-decoration: none;
}
a:hover { color: lighten($blue, 10%); }
a:focus { outline: none; }


/*******************************************************************
 * Headers
 *******************************************************************/

$h1-font-size: em-calc(30) !default;
$h2-font-size: em-calc(24) !default;
$h3-font-size: em-calc(22) !default;
$h4-font-size: em-calc(16) !default;
$h5-font-size: em-calc(16) !default;
$h6-font-size: 1em !default;

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  color: black;
  font-weight: bold;
  font-style: normal;
  line-height: 1.4;
  margin: 0 0 .5em;
}

h1 { font-size: $h1-font-size; }
h2 { font-size: $h2-font-size; }
h3 { font-size: $h3-font-size; @include pt-sans-narrow; @include fontsmoothing; font-weight: 500; text-transform: uppercase; color: $grey; }
h4 { font-size: $h4-font-size; color: $black; }
h5 { font-size: $h5-font-size; color: $black; }
h6 { font-size: $h6-font-size; color: $black; }

h3 + h3 { margin-top: 1.2em; }
h4 + h4 { margin-top: 1.5em; }


/*******************************************************************
 * Paragraphs
 *******************************************************************/

p {
  line-height: 1.6;
  margin: 0 0 em-calc(20);
  text-rendering: optimizeLegibility;
}

/*******************************************************************
 * Lists
 *******************************************************************/

// Unstyle lists. Extend class and mixin. Use mixin inside media queries if necessary.
@mixin unstyle-list { list-style: none; margin: 0; padding: 0; }

ul, ol {
  line-height:1.5; padding-left: 1em;
}


/*******************************************************************
 * Blockquotes
 *******************************************************************/

blockquote {
  line-height: 1.7; border-left: 2px solid #000; margin: 0 0 1.5em; padding: 1em; quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: shade(#000,10%); content: open-quote; font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.05em;
  vertical-align: -0.4em;
}
blockquote p { display: inline; }


/*******************************************************************
 * Misc Global Styles
 *******************************************************************/

hr          { height: 1px; color: #ccc; background: #ccc; font-size: 0; border: 0; }
article hr  { margin: 15px 0; }

.gold-title {
  color: $ltgold;
  @include crimson-text;
  @include fontsmoothing;
  font-size: em-calc(36);
}

.introductionText {
  @include crimson-text;
  @include fontsmoothing;
  font-weight: 700;
  font-size: em-calc(24);
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 3rem;
}

.introductionText--gold {
  color: $gold;
  @include crimson-text;
  @include fontsmoothing;
  font-weight: 500;
  font-size: 2em;
  line-height: 1.2em;
  text-align: left;
  margin-bottom: 3rem;
}

.entryBody {
  font-size: em-calc(18px);
  @include fontsmoothing;
}

.big-blue-title {
  color: $blue;
  font-size: 3em;
  @include pt-sans-narrow;
  @include fontsmoothing;
}

.subHeading {
  margin-bottom: 0.5em;
  @include crimson-text;
  @include fontsmoothing;
  font-size: 1.8em;
  color: $blue;
}

.justified_content {
  text-align: justify;
}

.text-center {
  text-align: center;
}