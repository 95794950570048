//
// Partnership Page Styles

.partners-wrapper {
  margin: 0 0 7vh;

  @media #{$medium-up} {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .partner {
    width: calc(100% - 10px);
    margin: 5px 5px 10px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$medium-up} {
      width: 48%;
      display: flex;
      flex-direction: column;
    }

    @media #{$large-up} {
      width: calc(33% - 20px);
      margin: 0 10px 25px;
    }

    .logo-wrapper {
      padding: 2rem 4rem;
      border-bottom: 1px solid #ddd;
      position: relative;
      height: 220px;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      display:block;
      object-fit: contain;
      object-position: center;
    }

    .content {
      padding: 2rem;
      
      p:last-of-type { margin-bottom: 0; }
    }

    .link a {
      display: block;
      width: 100%;
      padding: 18px 20px;
      background-color: $blue;
      color: #fff;
      text-align: center;
      font-size: em-calc(16px);

      &:hover { background-color: darken($blue, 10%); }
    }
  }
}
