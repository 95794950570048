.btn {
  appearance: none;
  @include pt-sans-narrow;
  @include fontsmoothing;
  display: inline-block;
  text-transform: uppercase;
  font-size: em-calc(18);
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 15px 20px;
  background-color: $blue;
  border: 1px solid $blue;
  line-height: 1.2;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s cubic-bezier(.21, 0, .36, 1),
              background-color 0.2s cubic-bezier(.21, 0, .36, 1),
              border-color 0.2s cubic-bezier(.21, 0, .36, 1);

  &:active { outline: 0; }

  &:hover {
    background-color: darken($blue, 20%);
    color: #fff;
  }
}

.btn--small {
  font-size: em-calc(14);
  padding: 7px 10px;
}
.btn--medium {
  font-size: em-calc(15);
  padding: 9px 12px;
}

.btn--filter {
  padding: 5px 12px;
  border-radius:20px;
  background:#fff;
  color:inherit;
  font-size:.9em;
  text-transform:none;
  border:1px solid #ddd;
  &.active {
    color:#ddd;
  }
}

.btn--icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  .icon {
    font-size: 20px;
    color: #FFF;
    margin: 0 10px;
  }
}

.btn--outline {
  background-color: transparent;
  border: 1px solid $blue;
  color: $blue;

  &:hover {
    color: darken($blue, 10%);
    border-color: darken($blue, 10%);
  }
}

.btn--white_outline {
  border-color: #fff;
  color:#fff;
  &:hover {
    color:$blue;
    background-color: $black;
  }
}

.btn--no_outline {
  border-color: transparent;
  color:#fff;
  padding:0;
  &:hover {
    border-color: transparent;
    color:$blue;
  }
}

//
// Button backgrounds
.btn--white_bg {
  background-color: #fff;
  border-color: #fff;
}

.btn--gold_bg {
  background-color: $gold;
  border-color:  $gold;

  &:hover {
    background-color: darken($gold, 10%);
  }
}

//
// Button Text
.btn--gold_text {
  color: $gold;

  &:hover {
    color: darken($gold, 10%);
  }

  .icon { color: $gold; }
}

.btn--blue_text {
  color: $blue;

  &:hover {
    color: darken($blue, 10%);
  }

  .icon { color: $blue; }
}

//
// Button hover
.btn--hover_blue {
  &:hover {
    color: #fff;
    background-color: $blue;
    border-color: $blue;

    .icon { color: #fff; }
  }
}

.btn--hover_gold {
  &:hover {
    color:#fff;
    background-color: $gold;
    border-color: $gold;

    .icon { color: #fff; }
  }
}
