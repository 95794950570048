

.freeform-pages {
    display:-ms-flexbox;
    display:flex;
    padding:0;
    margin:0 0 10px;
    list-style:none;

    li {
        margin:0 10px 0 0;
    }
}
.freeform-row {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:justify;
    justify-content:space-between;
    margin:0 -15px;
    flex-wrap: wrap;

    .freeform-column {
        -ms-flex:1 0 auto;
        flex:1 0 auto;
        padding:10px 0;
        margin:0 15px;
        box-sizing:border-box;
        max-width: 100%;

        div {
            max-width: 96%;
        }

        label {
            display:block;
            padding-bottom: .5em;
        }

        .freeform-label {
            font-weight:bold;
            padding-bottom: .5em;
            font-size: 1.1em;

            &.freeform-required:after {
                content:"*";
                margin-left:5px;
                color:red;
            }

        }

        .freeform-input {
            width:100%;
            display:block;
            box-sizing:border-box;
            font-family:inherit;
            outline:none;

            &[type=checkbox], &[type=radio] {
                width:auto;
                display:inline;
                margin-right:5px;
                vertical-align: top;
            }

            &[type=text], &[type=email], &[type=password] {
                background: #fff;
                border: 1px solid #ddd;
                padding: .5em;
            }
        }

        select.freeform-input {
            background: #fff;
            border: 1px solid #ddd;
            padding: .5em;
        }

        textarea.freeform-input {
            background: #fff;
            border: 1px solid #ddd;
            padding: .5em;
        }

        .freeform-input-only-label {
            font-weight:normal;

            &>.freeform-input{
                display:inline-block;
                width:auto;
                margin-right:5px;
            }
        }

        .freeform-errors {
            list-style:none;
            padding:0;
            margin:5px 0 0;

            &>li {
                color:red
            }

        }

        .freeform-instructions {
            margin:0 0 1em;
            font-style: italic;
        }

        &.freeform-column-content-align-left {
            display:-ms-flexbox;
            display:flex;
            -ms-flex-pack:start;
            justify-content:flex-start;

            &>button:not(:first-of-type) {
                margin-left:5px;
            }
        }

        &.freeform-column-content-align-center {
            display:-ms-flexbox;
            display:flex;
            -ms-flex-pack:center;
            justify-content:center;

            &>button:not(:first-of-type) {
                margin-left:5px;
            }

        }

        &.freeform-column-content-align-right {
            display:-ms-flexbox;
            display:flex;
            -ms-flex-pack:end;
            justify-content:flex-end;

            &>button:not(:first-of-type) {
                margin-left:5px
            }
        }

        &.freeform-column-content-align-spread {
            display:-ms-flexbox;
            display:flex;
            -ms-flex-pack:justify;
            justify-content:space-between;

            &>button:not(:first-of-type) {
                margin-left:5px;
            }

        }

    }

}

.freeform-form-has-errors{
    padding:1.75em 2em;
    margin-bottom: 2em;
    background:rgba(255,0,0,.1);
    border: 1px solid rgba(255,0,0,.2);
}

form fieldset {
    border: 1px solid #ddd;
    display: block;
    padding: 1.5em 2em 1.75em;
    margin-bottom: 2em;
    background: #fff;

    legend {
        background: $gold;
        color: #fff;
        margin-left: -1em;
        padding: .7em 1em .8em;
    }
}

//
// Hide fields
.freeform-hidden {
    display: none;
}
