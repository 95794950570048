//
// Full Width Page Styles
//

.full-width {
  margin-top: 5vh;

  .breadcrumbs { margin-bottom: 5vh; }

  .introductionText {
    @extend .introductionText--gold;
    max-width: none;
  }

  .entryBody { max-width: none; }
}