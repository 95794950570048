//
// Venue Map and Seating Chart Styles
//

.mapLegend {
  margin-bottom: 5vh;

  .subHeading { margin-bottom: 4vh; }

  ul {
    padding: 0;

    @media #{$medium-up} {
      column-count: 3;
      column-gap: 2vh;
    }

    li {
      list-style-type: none;
      margin-bottom: 3vh;
      text-transform: uppercase;

      svg {
        margin-right: 8px;
        width: 28px;
        height: 28px;
      }
    }
  }
}