// Page Banner with Image
.page_banner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-image: linear-gradient(-180deg, #414141 1%, #000000 100%);
  margin-bottom: 8vh;
  width: 100%;

  @media #{$medium-up} {
    flex-direction: row;
  }
}

.page_banner__image { 
  min-height: 50vh; 
  
  @media #{$medium-up} {
    width: 50%;
  }
}

.page_banner__content {
  width: 100%;
  padding: 2% 3% 5% 3%;

  @media #{$medium-up} {
    width: 50%;
  }
}

.page_banner .breadcrumbs {
  @include fontsmoothing;
  font-weight: 700;
  margin-bottom: 5vh;
}

.page_banner__title {
  @include pt-sans-narrow;
  @include fontsmoothing;
  color: #fff;
  font-size: em-calc(30);
  line-height: 1em;

  @media #{$medium-up} {
    font-size: em-calc(46);
  }
}

.page_banner__intro_text {
  p {
    @include crimson-text;
    @include fontsmoothing;
    font-size: em-calc(24);
    line-height: 1.3em;
    color: $gold;

    @media #{$medium-up} {
      font-size: em-calc(28);
    }
  }
}