@charset "UTF-8";
/*******************************************************************
 * Functions
 *******************************************************************/
/*******************************************************************
 * Variables
 *******************************************************************/
/*******************************************************************
 * Grid Variables
 *******************************************************************/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade_down-enter-active,
.fade_down-leave-active {
  transition: opacity 0.3s cubic-bezier(0.21, 0, 0.36, 1), transform 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.fade_down-enter,
.fade_down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary { /* 1 */
  display: block;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none;
}

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: inherit; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box; /* 1 */
  cursor: default; /* 2 */
  font-family: sans-serif; /* 3 */
  line-height: 1.5; /* 3 */
  -ms-text-size-adjust: 100%; /* 4 */
  -webkit-text-size-adjust: 100%; /* 4 */
}

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font sizes and margins on `h1` elements within
 * `section` and `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted; /* 1 */
  text-decoration: none; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%;
}

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%; /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0;
}

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent; /* 1 */
  border-style: none; /* 1 */
  color: inherit; /* 1 */
  font-size: 1em; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}

/**
 * Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  padding: 0;
}

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden=false] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden=false]:focus {
  clip: auto;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 20px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 20px;
}

.leaflet-left .leaflet-control {
  margin-left: 20px;
}

.leaflet-right .leaflet-control {
  margin-right: 20px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #fff;
  outline: 0;
}

#venue-map.leaflet-container {
  background: #eaeaea;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #AA9157;
  border-bottom: 1px solid #fff;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding-right: 2px;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #897445;
}

.leaflet-bar a:last-child {
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #897445;
  color: #fff;
}

.leaflet-touch .leaflet-bar a {
  width: 36px;
  height: 36px;
  line-height: 32px;
  right: 20px;
}
@media only screen and (min-width:64.0625em) {
  .leaflet-touch .leaflet-bar a {
    width: 50px;
    height: 50px;
    line-height: 47px;
  }
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-control-zoom-out {
  font-size: 20px;
}

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px;
}

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

.leaflet-info {
  padding: 8px 10px;
  background: white;
  font-size: 13px;
  font-family: "PT Sans", sans-serif;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
@media only screen and (min-width:64.0625em) {
  .leaflet-info {
    font-size: 16px;
    padding: 14px 16px;
  }
}
.leaflet-info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.leaflet-info ul li {
  padding: 2px 0;
  display: block;
}
@media only screen and (min-width:64.0625em) {
  .leaflet-info ul li {
    padding: 4px 0;
  }
}
.leaflet-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.leaflet-legend {
  line-height: 24px;
  color: #555;
}
.leaflet-legend i {
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 8px;
}
@media only screen and (min-width:64.0625em) {
  .leaflet-legend i {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

/**
 * wallop.css
 *
 * @fileoverview Default styles for wallop – recommended
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */
.Wallop {
  position: relative;
}

.Wallop-list {
  position: relative;
  overflow: hidden;
}

.Wallop-item {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.Wallop-item--current {
  visibility: visible;
  position: relative;
}

/**
 * wallop--slide.css
 *
 */
/* the 10ms animation-delay fixed some weird rendering issues with iPhone */
.Wallop--slide .Wallop-item--showPrevious {
  animation: slideFromLeft 350ms 10ms linear both;
}
.Wallop--slide .Wallop-item--showNext {
  animation: slideFromRight 350ms 10ms linear both;
}
.Wallop--slide .Wallop-item--hidePrevious, .Wallop--slide .Wallop-item--hideNext {
  visibility: visible;
}
.Wallop--slide .Wallop-item--hidePrevious {
  animation: slideToLeft 350ms 10ms linear both;
}
.Wallop--slide .Wallop-item--hideNext {
  animation: slideToRight 350ms 10ms linear both;
}

@keyframes slideFromLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideFromRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideToLeft {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideToRight {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
/*! Flickity v2.0.9
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: white;
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.gallery-wrapper {
  position: relative;
  margin-bottom: 4em;
}

#gallery {
  width: 100%;
  /*height: 300px;*/
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.gallery-prev, .gallery-next {
  font-size: 2em;
  position: absolute;
  top: 48%;
  padding: 6px 18px 12px;
}

.gallery-prev svg, .gallery-next svg {
  font-size: inherit;
}

.gallery-prev {
  left: -10px;
}

.gallery-next {
  right: -10px;
}

.carousel-cell {
  width: 100%;
}
.carousel-cell img {
  width: 100%;
}

.caption-wrapper {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
  text-align: center;
}
.caption-wrapper .caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 18px;
  border-radius: 30px;
}

.gallery-nav {
  margin-bottom: 1em;
  text-align: center;
}
.gallery-nav button {
  margin: 0 2px 6px;
}

/*******************************************************************
 * Type Defaults
 *******************************************************************/
html,
body {
  font-size: 16px;
}

body {
  font-family: "PT Sans", sans-serif;
  color: #444;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
}

i {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}

a {
  transition: color 0.2s cubic-bezier(0.21, 0, 0.36, 1), background-color 0.2s cubic-bezier(0.21, 0, 0.36, 1);
  color: #277DDE;
  text-decoration: none;
}

a:hover {
  color: #5398e5;
}

a:focus {
  outline: none;
}

/*******************************************************************
 * Headers
 *******************************************************************/
/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  color: black;
  font-weight: bold;
  font-style: normal;
  line-height: 1.4;
  margin: 0 0 0.5em;
}

h1 {
  font-size: 1.875em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.375em;
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  text-transform: uppercase;
  color: #A6A6A6;
}

h4 {
  font-size: 1em;
  color: #111111;
}

h5 {
  font-size: 1em;
  color: #111111;
}

h6 {
  font-size: 1em;
  color: #111111;
}

h3 + h3 {
  margin-top: 1.2em;
}

h4 + h4 {
  margin-top: 1.5em;
}

/*******************************************************************
 * Paragraphs
 *******************************************************************/
p {
  line-height: 1.6;
  margin: 0 0 1.25em;
  text-rendering: optimizeLegibility;
}

/*******************************************************************
 * Lists
 *******************************************************************/
ul, ol {
  line-height: 1.5;
  padding-left: 1em;
}

/*******************************************************************
 * Blockquotes
 *******************************************************************/
blockquote {
  line-height: 1.7;
  border-left: 2px solid #000;
  margin: 0 0 1.5em;
  padding: 1em;
  quotes: "“" "”" "‘" "’";
}

blockquote:before {
  color: shade(#000, 10%);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.05em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

/*******************************************************************
 * Misc Global Styles
 *******************************************************************/
hr {
  height: 1px;
  color: #ccc;
  background: #ccc;
  font-size: 0;
  border: 0;
}

article hr {
  margin: 15px 0;
}

.gold-title {
  color: #DBB96B;
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.25em;
}

.introductionText {
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 3rem;
}

.introductionText--gold, .full-width .introductionText, .basic .introductionText {
  color: #AA9157;
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 2em;
  line-height: 1.2em;
  text-align: left;
  margin-bottom: 3rem;
}

.entryBody {
  font-size: 1.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.big-blue-title {
  color: #277DDE;
  font-size: 3em;
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subHeading, .mapWrapper h5 {
  margin-bottom: 0.5em;
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8em;
  color: #277DDE;
}

.justified_content {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.btn, .home_slider .Wallop-list .Wallop-item .bookingLink, .event_banner__footer .event_banner__ticket_btn .btn {
  appearance: none;
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.125em;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 15px 20px;
  background-color: #277DDE;
  border: 1px solid #277DDE;
  line-height: 1.2;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s cubic-bezier(0.21, 0, 0.36, 1), background-color 0.2s cubic-bezier(0.21, 0, 0.36, 1), border-color 0.2s cubic-bezier(0.21, 0, 0.36, 1);
}
.btn:active, .home_slider .Wallop-list .Wallop-item .bookingLink:active, .event_banner__footer .event_banner__ticket_btn .btn:active {
  outline: 0;
}
.btn:hover, .home_slider .Wallop-list .Wallop-item .bookingLink:hover, .event_banner__footer .event_banner__ticket_btn .btn:hover {
  background-color: #154c8a;
  color: #fff;
}

.btn--small {
  font-size: 0.875em;
  padding: 7px 10px;
}

.btn--medium, .home_slider .Wallop-list .Wallop-item .bookingLink, .event_banner__footer .event_banner__ticket_btn .btn {
  font-size: 0.9375em;
  padding: 9px 12px;
}

.btn--filter {
  padding: 5px 12px;
  border-radius: 20px;
  background: #fff;
  color: inherit;
  font-size: 0.9em;
  text-transform: none;
  border: 1px solid #ddd;
}
.btn--filter.active {
  color: #ddd;
}

.btn--icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.btn--icon .icon {
  font-size: 20px;
  color: #FFF;
  margin: 0 10px;
}

.btn--outline, .home_slider .Wallop-list .Wallop-item .bookingLink:not(:first-child), .event_banner__footer .event_banner__ticket_btn .parking-btn {
  background-color: transparent;
  border: 1px solid #277DDE;
  color: #277DDE;
}
.btn--outline:hover, .home_slider .Wallop-list .Wallop-item .bookingLink:hover:not(:first-child), .event_banner__footer .event_banner__ticket_btn .parking-btn:hover {
  color: #1c64b6;
  border-color: #1c64b6;
}

.btn--white_outline, .home_slider .Wallop-list .Wallop-item .bookingLink:not(:first-child), .event_banner__footer .event_banner__ticket_btn .parking-btn {
  border-color: #fff;
  color: #fff;
}
.btn--white_outline:hover, .home_slider .Wallop-list .Wallop-item .bookingLink:hover:not(:first-child), .event_banner__footer .event_banner__ticket_btn .parking-btn:hover {
  color: #277DDE;
  background-color: #111111;
}

.btn--no_outline {
  border-color: transparent;
  color: #fff;
  padding: 0;
}
.btn--no_outline:hover {
  border-color: transparent;
  color: #277DDE;
}

.btn--white_bg {
  background-color: #fff;
  border-color: #fff;
}

.btn--gold_bg {
  background-color: #AA9157;
  border-color: #AA9157;
}
.btn--gold_bg:hover {
  background-color: #897445;
}

.btn--gold_text {
  color: #AA9157;
}
.btn--gold_text:hover {
  color: #897445;
}
.btn--gold_text .icon {
  color: #AA9157;
}

.btn--blue_text {
  color: #277DDE;
}
.btn--blue_text:hover {
  color: #1c64b6;
}
.btn--blue_text .icon {
  color: #277DDE;
}

.btn--hover_blue:hover {
  color: #fff;
  background-color: #277DDE;
  border-color: #277DDE;
}
.btn--hover_blue:hover .icon {
  color: #fff;
}

.btn--hover_gold:hover {
  color: #fff;
  background-color: #AA9157;
  border-color: #AA9157;
}
.btn--hover_gold:hover .icon {
  color: #fff;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
   * Don't show links for images, or javascript/internal links
   */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group; /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
.icon-wineglass {
  width: 0.5908203125em;
}

.icon-plus {
  width: 0.7857142857em;
}

.icon-minus {
  width: 0.7857142857em;
}

.icon-th-large {
  width: 0.9285714286em;
}

.icon-clock-o {
  width: 0.8571428571em;
}

.icon-road {
  width: 1.0714285714em;
}

.icon-tag {
  width: 0.8454285714em;
}

.icon-map-marker {
  width: 0.5714285714em;
}

.icon-question-circle {
  width: 0.8571428571em;
}

.icon-calendar {
  width: 0.9285714286em;
}

.icon-twitter {
  width: 0.9285714286em;
}

.icon-facebook {
  width: 0.5881428571em;
}

.icon-arrow-circle-left {
  width: 0.8571428571em;
}

.icon-arrow-circle-right {
  width: 0.8571428571em;
}

.icon-arrow-circle-up {
  width: 0.8571428571em;
}

.icon-arrow-circle-down {
  width: 0.8571428571em;
}

.icon-arrows-alt {
  width: 0.8571428571em;
}

.icon-pinterest {
  width: 0.8571428571em;
}

.icon-coffee {
  width: 1.0357142857em;
}

.icon-angle-left {
  width: 0.375em;
}

.icon-angle-right {
  width: 0.3392857143em;
}

.icon-angle-up {
  width: 0.6428571429em;
}

.icon-angle-down {
  width: 0.6428571429em;
}

.icon-instagram {
  width: 0.8571428571em;
}

.icon-bus {
  width: 0.8571428571em;
}

.icon-play {
  width: 0.8571428571em;
}

.icon-close {
  width: 0.8571428571em;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}

object,
embed {
  height: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

img {
  display: inline-block;
}

[v-cloak] {
  display: none;
}

body {
  padding: 0;
  margin: 0;
  background-color: #F3F3F3;
}

.site_wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}
main::after {
  clear: both;
  content: "";
  display: table;
}

.container {
  max-width: 1400px;
  padding: 0 4%;
  margin: 0 auto;
}
@media only screen and (min-width:64.0625em) {
  .container {
    padding: 0 2%;
  }
}

.introductionText,
.entryBody, .mapWrapper, .textBlock, .healthAlert {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.healthAlert {
  background-color: #fff;
  border: 1px solid #A6A6A6;
  margin-bottom: 55px;
  padding: 30px 30px 10px;
}

.breadcrumbs {
  color: #A6A6A6;
  font-family: "PT Sans Narrow", sans-serif;
}
.breadcrumbs a {
  color: #AA9157;
}

.article_footer_nav {
  width: 100%;
  margin-top: 7vh;
}
@media only screen and (min-width:40.0625em) {
  .article_footer_nav {
    display: flex;
    flex-wrap: wrap;
  }
}
.article_footer_nav .btn, .article_footer_nav .home_slider .Wallop-list .Wallop-item .bookingLink, .home_slider .Wallop-list .Wallop-item .article_footer_nav .bookingLink, .article_footer_nav .event_banner__footer .event_banner__ticket_btn .btn, .event_banner__footer .event_banner__ticket_btn .article_footer_nav .btn {
  flex: 1;
  font-size: 1.625em;
  padding: 18px 20px;
}
.article_footer_nav .icon {
  font-size: 26px;
  width: 46px;
}

.preheader {
  background: #303030;
  padding: 1em 2em;
  text-align: center;
  position: relative;
  z-index: 101;
}
@media only screen and (min-width:40.0625em) {
  .preheader__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.preheader__copy {
  color: #EDCB80;
  flex-basis: 2;
  padding: 0.15em 0.5em;
}
.preheader__action {
  padding: 0 0.5em 0;
}

.site_header {
  background-color: #fff;
  padding-bottom: 130px;
  position: relative;
  z-index: 101;
}
@media only screen and (min-width:40.0625em) {
  .site_header {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.site_header .header-home {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 280px;
}
@media only screen and (min-width:40.0625em) {
  .site_header .header-home {
    height: auto;
    float: left;
    margin: 0 auto;
  }
}
.site_header .header-home svg {
  max-height: 43px;
  position: absolute;
  top: 60px;
}
@media only screen and (min-width:40.0625em) {
  .site_header .header-home svg {
    width: 280px;
    position: static;
  }
}
.site_header .main-nav {
  width: 100%;
  position: absolute;
  top: 130px;
  background-color: #fff;
  z-index: 100;
}
.site_header .main-nav::after {
  clear: both;
  content: "";
  display: table;
}
@media only screen and (min-width:40.0625em) {
  .site_header .main-nav {
    position: static;
    width: auto;
  }
}
@media only screen and (min-width:40.0625em) and (max-width:64em) {
  .site_header .main-nav {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width:64.0625em) {
  .site_header .main-nav {
    flex: 1;
  }
}
.site_header .main-nav input, .site_header .main-nav label {
  display: none;
}
.site_header .main-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.site_header .main-nav ul li {
  display: inline-block;
  padding: 0 1.5%;
}
@media only screen and (min-width:90.0625em) {
  .site_header .main-nav ul li {
    padding: 0 3%;
  }
}
.site_header .main-nav ul li:nth-child(2) {
  padding-left: 0;
}
.site_header .main-nav ul li:last-child {
  padding-right: 0;
}
@media only screen and (min-width:40.0625em) and (max-width:64em) {
  .site_header .main-nav ul li {
    padding: 10px;
  }
}
.site_header .main-nav ul li.mobile-only {
  display: none;
}
.site_header .main-nav ul li:hover > ul {
  display: inherit;
}
.site_header .main-nav ul ul {
  display: none;
  position: absolute;
  text-align: left;
}
@media only screen and (min-width:40.0625em) {
  .site_header .main-nav ul ul {
    padding-top: 10px;
    width: 86%;
    left: 7%;
  }
  .site_header .main-nav ul ul > div {
    padding: 4%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.29);
  }
  .site_header .main-nav ul ul > div::after {
    clear: both;
    content: "";
    display: table;
  }
  .site_header .main-nav ul ul li {
    float: left;
    clear: left;
    width: 27%;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #EEEEEE;
    display: list-item;
  }
  .site_header .main-nav ul ul li:last-child {
    border-bottom: 0;
  }
  .site_header .main-nav ul ul li a {
    font-weight: 700;
  }
  .site_header .main-nav ul ul li a:hover {
    color: #AA9157;
  }
}
@media only screen and (min-width:64.0625em) {
  .site_header .main-nav ul ul {
    padding-top: 30px;
    width: 60%;
    left: 20%;
  }
}
.site_header .main-nav ul ul .childExtendedWrapper {
  width: 68%;
  float: right;
  display: flex;
}
.site_header .main-nav ul ul li.extended {
  display: none;
  float: right;
  width: 100%;
  border-bottom: 0;
}
@media only screen and (min-width:40.0625em) {
  .site_header .main-nav ul ul li.extended {
    display: block;
  }
}
.site_header .main-nav ul ul li.extended .bodyImageWrapper {
  display: flex;
}
.site_header .main-nav ul ul li.extended .bodyImageWrapper p {
  margin-bottom: 2px;
  line-height: 1.4em;
  font-size: 0.9em;
}
.site_header .main-nav ul ul li.extended .imageWrapper {
  min-width: 40%;
  padding-right: 10px;
}
.site_header .main-nav ul ul li.extended .imageWrapper img {
  width: 100%;
  height: auto;
}
.site_header .main-nav ul ul li.extended .links {
  margin-top: 10px;
}
.site_header .main-nav ul ul li.extended .links p {
  margin: 0;
}
.site_header .main-nav ul a {
  font-family: "PT Sans Narrow", sans-serif;
  text-transform: uppercase;
  color: #303030;
}
.site_header .main-nav ul a .icon-wrap {
  display: none;
  width: 24px;
  text-align: center;
}
@media only screen and (min-width:40.0625em) {
  .site_header .main-nav ul a .icon-wrap {
    display: inline-block;
  }
}
.site_header .main-nav ul a .icon {
  font-size: 20px;
  height: 14px;
  margin-right: 5px;
}
.site_header .main-nav ul .arrow-icon {
  display: none;
}
@media only screen and (min-width:64.0625em) {
  .site_header .main-nav ul .arrow-icon {
    display: none;
    position: relative;
    color: #277DDE;
    width: 100%;
  }
  .site_header .main-nav ul .arrow-icon svg {
    position: absolute;
    top: -7px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 2em;
    height: 36px;
  }
  .site_header .main-nav ul li:hover .arrow-icon {
    display: block;
  }
}
@media only screen and (max-width: 40em) {
  .site_header .main-nav .toggle + a, .site_header .main-nav .menu {
    display: none;
    width: 100%;
  }
  .site_header .main-nav .toggle {
    display: block;
    text-transform: uppercase;
    font-family: "PT Sans Narrow", sans-serif;
  }
  .site_header .main-nav .box-shadow-menu {
    padding: 10px;
    position: relative;
    color: #fff;
    background-color: #277DDE;
    font-size: 1.4em;
  }
  .site_header .main-nav .box-shadow-menu:after {
    content: "";
    position: absolute;
    right: 2%;
    top: 0.6em;
    width: 1em;
    height: 0.1em;
    background: #fff;
    box-shadow: 0 0.27em 0 0 #fff, 0 0.53em 0 0 #fff;
  }
  .site_header .main-nav [id^=drop]:checked + ul {
    display: block;
  }
  .site_header .main-nav .mobile-only {
    display: block;
  }
  .site_header .main-nav > ul > li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #F3F3F3;
    padding: 10px;
    margin: 0;
  }
  .site_header .main-nav ul ul {
    float: none;
    position: static;
    margin-top: 10px;
  }
  .site_header .main-nav ul ul li:hover > ul,
  .site_header .main-nav ul li:hover > ul {
    display: none;
  }
  .site_header .main-nav ul ul li {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .site_header .main-nav ul ul li a {
    color: #277DDE;
    padding: 5px;
    display: block;
  }
  .site_header .main-nav ul ul li a svg {
    display: none;
  }
  .site_header .main-nav li label .arrow-icon {
    display: inline;
    margin-left: 5px;
  }
  .site_header .main-nav li label .arrow-icon svg {
    height: 24px;
    top: -3px;
  }
  .site_header .main-nav li:hover label .arrow-icon {
    display: inline;
  }
}
.site_header .social {
  display: flex;
  align-items: center;
  min-width: 220px;
  height: 40px;
}
@media only screen and (min-width:40.0625em) and (max-width:64em) {
  .site_header .social {
    order: -1;
    width: 100%;
    margin-bottom: 1em;
  }
}
@media only screen and (min-width:64.0625em) {
  .site_header .social {
    height: auto;
  }
}
.site_header .social .social-icons {
  width: 50%;
  float: left;
  text-align: center;
  margin: 0;
  padding: 0;
}
.site_header .social .social-icons a {
  color: #414141;
  transition: opacity 0.2s cubic-bezier(0.21, 0, 0.36, 1);
}
.site_header .social .social-icons a:hover {
  opacity: 0.8;
}
.site_header .social .social-icons .icon {
  font-size: 20px;
}
.site_header .social li {
  display: inline-block;
  list-style-type: none;
}
.site_header .social a {
  width: 20px;
  margin: 0 2px;
  display: block;
}
.site_header .mailing-list {
  width: 50%;
  height: 40px;
  padding-top: 11px;
  float: left;
  text-align: center;
  background-color: #AA9157;
  transition: background-color 0.2s cubic-bezier(0.21, 0, 0.36, 1);
}
.site_header .mailing-list:hover {
  background-color: #b7964a;
}
.site_header .mailing-list .icon {
  font-size: 18px;
  margin: 0 5px 0 0;
  vertical-align: text-top;
}
.site_header .mailing-list a {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
@media only screen and (min-width:64.0625em) {
  .site_header .mailing-list {
    height: auto;
    padding-top: 0;
  }
  .site_header .mailing-list .icon {
    display: block;
    margin: 0 auto 7px;
  }
  .site_header .mailing-list a {
    padding: 11% 2%;
  }
}

.sponsor-banner {
  position: relative;
  background-color: #111111;
  z-index: 999;
}
.sponsor-banner a {
  display: block;
  text-decoration: none;
}
.sponsor-banner img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-width: 1400px;
}
.sponsor-banner .sponsor-banner__image {
  display: none;
}
@media only screen and (min-width:40.0625em) {
  .sponsor-banner .sponsor-banner__image {
    display: block;
  }
  .sponsor-banner .sponsor-banner__mobile-image {
    display: none;
  }
}
@media only screen and (min-width:90.0625em) {
  .sponsor-banner {
    padding: 20px;
  }
}

.page_banner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-image: linear-gradient(-180deg, #414141 1%, #000000 100%);
  margin-bottom: 8vh;
  width: 100%;
}
@media only screen and (min-width:40.0625em) {
  .page_banner {
    flex-direction: row;
  }
}

.page_banner__image {
  min-height: 50vh;
}
@media only screen and (min-width:40.0625em) {
  .page_banner__image {
    width: 50%;
  }
}

.page_banner__content {
  width: 100%;
  padding: 2% 3% 5% 3%;
}
@media only screen and (min-width:40.0625em) {
  .page_banner__content {
    width: 50%;
  }
}

.page_banner .breadcrumbs {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  margin-bottom: 5vh;
}

.page_banner__title {
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 1.875em;
  line-height: 1em;
}
@media only screen and (min-width:40.0625em) {
  .page_banner__title {
    font-size: 2.875em;
  }
}

.page_banner__intro_text p {
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5em;
  line-height: 1.3em;
  color: #AA9157;
}
@media only screen and (min-width:40.0625em) {
  .page_banner__intro_text p {
    font-size: 1.75em;
  }
}

.toolbar_header {
  margin: 4vh 0;
}
@media only screen and (min-width:40.0625em) {
  .toolbar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.toolbar_header__title {
  color: #303030;
  font-size: 3em;
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
}

.toolbar_header__tools {
  display: flex;
  align-items: center;
}
.toolbar_header__tools > * {
  display: inline-block;
  margin-right: 8px;
}
.toolbar_header__tools .toolbar_action_title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 16px;
}
.toolbar_header__tools .toolbar_icons {
  display: flex;
  align-items: center;
}
.toolbar_header__tools .toolbar_icons button {
  appearance: none;
  padding: 0;
  margin-right: 8px;
}
.toolbar_header__tools .toolbar_icons .active {
  outline: none;
}
.toolbar_header__tools .toolbar_icons .active .icon {
  color: #277DDE;
}
.toolbar_header__tools .toolbar_icons .icon {
  color: #414141;
  font-size: 18px;
}
.toolbar_header__tools .toolbar_icons + .toolbar__text_link {
  border-left: 1px solid #A6A6A6;
  margin-left: 8px;
  padding-left: 16px;
}
.toolbar_header__tools .toolbar__text_link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #AA9157;
}

footer {
  padding: 50px 0;
  background-color: #303030;
  position: relative;
  z-index: 100;
}
footer .container::after {
  clear: both;
  content: "";
  display: table;
}
footer .tree {
  padding-right: 14px;
}
footer .tree svg {
  width: 110px;
  display: block;
  margin: 0 auto 20px;
}
footer .address {
  text-align: center;
  color: #CECECE;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
footer .address h3, footer .address h4 {
  font-family: "PT Sans Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  text-transform: uppercase;
}
footer .address h4 {
  font-weight: 400;
}
footer .address a {
  color: #fff;
}
footer .address p {
  line-height: 1.3em;
}
footer .links {
  width: 100%;
  float: right;
  text-align: center;
  margin-top: 10px;
}
footer .links a {
  margin-left: 15px;
  font-family: "PT Sans Narrow", sans-serif;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 1.5em;
  color: #AA9157;
}
footer .links a:hover {
  color: #897445;
}
footer .copyright {
  font-family: "Crimson Text", serif;
  color: #838383;
  margin-top: 20px;
  line-height: 2em;
}
footer .copyright a {
  font-size: 0.875em;
  color: #838383;
}
footer .copyright img {
  height: 25px;
  position: relative;
  top: -4px;
}
@media only screen and (min-width:64.0625em) {
  footer .tree, footer .address {
    width: auto;
    float: left;
  }
  footer .address {
    text-align: left;
  }
  footer .links {
    width: auto;
    text-align: right;
  }
}

.site_wrapper .v--modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal__close_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  cursor: pointer;
}
.modal__close_btn svg {
  color: white;
  width: 1.5rem;
  height: 1.5rem;
}

.v--modal-overlay .v--modal-box {
  overflow: visible !important;
}

.v--modal-box .modal-container {
  position: relative;
  background-color: #277DDE;
  color: white;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width:64.0625em) {
  .v--modal-box .modal-container {
    height: auto;
    width: 66%;
    max-width: 768px;
  }
}
.v--modal-box .modal-container > div {
  padding: 2em;
}
@media only screen and (min-width:40.0625em) {
  .v--modal-box .modal-container > div {
    padding: 2.5em;
  }
}
@media only screen and (min-width:64.0625em) {
  .v--modal-box .modal-container > div {
    padding: 3.5em;
  }
}
.v--modal-box .modal-container > div > button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 1em 1em 0 0;
  color: white;
}
.v--modal-box .modal-container > div > button svg {
  width: 24px;
  height: 24px;
}
.v--modal-box .modal-container > div .btn, .v--modal-box .modal-container > div .home_slider .Wallop-list .Wallop-item .bookingLink, .home_slider .Wallop-list .Wallop-item .v--modal-box .modal-container > div .bookingLink {
  margin-top: 0.5em;
}
.v--modal-box .modal-container > div .btn--icon {
  display: inline-flex;
}
.v--modal-box .modal-container > div .btn--white_outline:hover, .v--modal-box .modal-container > div .home_slider .Wallop-list .Wallop-item .bookingLink:hover:not(:first-child), .home_slider .Wallop-list .Wallop-item .v--modal-box .modal-container > div .bookingLink:hover:not(:first-child), .v--modal-box .modal-container > div .event_banner__footer .event_banner__ticket_btn .parking-btn:hover, .event_banner__footer .event_banner__ticket_btn .v--modal-box .modal-container > div .parking-btn:hover {
  border-color: white;
  color: white;
}
.v--modal-box .modal-container > div a {
  color: white;
  text-decoration: underline;
}

.site_wrapper .v--modal-box {
  position: relative;
}

.video-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.accordion-menu input[type=checkbox] {
  /* hide native checkbox */
  position: absolute;
  opacity: 0;
}

.accordion-menu {
  padding: 0;
  margin: 0 0 5vh;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.accordion-menu > li {
  position: relative;
  list-style-type: none;
}
.accordion-menu > li > ul {
  /* by default hide all sub menus */
  display: none;
  padding: 0;
  margin: 0;
}
.accordion-menu > li > ul > li {
  position: relative;
  list-style-type: none;
}

.accordion-menu label .title-wrapper {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.accordion-menu label h4 {
  display: inline;
  padding: 10px 0 10px 20px;
  color: #277DDE;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  margin: 0;
}
.accordion-menu label .arrow-icon {
  font-size: 20px;
  padding: 10px 25px;
  background-color: #FBF9F6;
}
.accordion-menu label svg {
  width: 10px;
  transform: rotate(90deg);
  transition: transform 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.accordion-menu input[type=checkbox]:checked + label + ul,
.accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
  /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
  /* show children when item is checked */
  display: block;
}

.accordion-menu input[type=checkbox]:checked + label div svg {
  transform: rotate(270deg);
}

.accordion-menu .answer {
  padding: 3vh;
  border-bottom: 1px solid #e9e9e9;
}
.accordion-menu .answer img {
  display: inline-block;
  padding: 0;
  margin: 0 0 1em;
  border: 5px solid white !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 47%;
  vertical-align: middle;
}
.accordion-menu .answer img:nth-child(odd) {
  margin-right: 2%;
}

.events_listings {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7vh;
  justify-content: space-between;
}
.events_listings:after {
  content: "";
  flex: auto;
}

.events_listings__event {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 3vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width:40.0625em) {
  .events_listings__event {
    width: 48%;
  }
  .events_listings__event:nth-child(2n-1) {
    margin-right: 4%;
  }
}
@media only screen and (min-width:64.0625em) {
  .events_listings__event {
    width: 31.5%;
  }
  .events_listings__event:nth-child(2n-1) {
    margin-right: auto;
  }
  .events_listings__event:nth-child(3n-1) {
    margin-left: 2.75%;
    margin-right: 2.75%;
  }
}
.events_listings__event > a {
  text-decoration: none;
}
.events_listings__event:hover .events_listings__img {
  transform: scale(1.1);
}
.events_listings__event:hover .events_listings__date {
  color: #bba779;
}

.events_listings__img {
  width: 100%;
  transition: all 0.5s;
  backface-visibility: hidden;
}

.events_listings__content {
  background-color: #111111;
  overflow: hidden;
  width: 100%;
  padding: 10px 25px 20px;
  position: relative;
  z-index: 100;
  flex: 1;
}

.events_listings__date {
  font-family: "Crimson Text", serif;
  color: #AA9157;
  font-size: 2.125em;
  margin: 0 0 16px -10px;
  line-height: 1;
  transition: color 0.3s;
}

.events_listings__pre_title, .events_listings__post_title {
  color: #A6A6A6;
  font-size: 0.875em;
  font-style: italic;
  margin: 0 64px 0 0;
  line-height: 1.3;
}

.events_listings__post_title {
  font-style: normal;
}

.events_listings__title {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 1.4375em;
  color: #fff;
  margin: 0 64px 0 0;
  font-weight: normal;
}

.events_listings__ticket_link {
  position: absolute;
  bottom: -64px;
  right: -64px;
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background-color: rgba(170, 145, 87, 0.7);
  transition: background-color 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.events_listings__ticket_link:hover {
  background-color: #AA9157;
}
.events_listings__ticket_link .icon {
  font-size: 32px;
  color: #fff;
  position: absolute;
  left: 21px;
  top: 21px;
}

.vr_calendar {
  border: 1px solid #d9d9d9;
}
.vr_calendar .title {
  font-family: "PT Sans Narrow", sans-serif;
  text-transform: uppercase;
  color: #AA9157;
}
.vr_calendar .week {
  color: #AA9157;
}
.vr_calendar .next-month, .vr_calendar .prev-month {
  color: #277DDE;
}

/**
 * AddToCalendar Base CSS
 * http://addtocalendar.com
 */
/* Base */
.addtocalendar var {
  display: none;
}

.addtocalendar {
  position: relative;
  display: inline-block;
  background: transparent !important;
}

.atcb-link {
  display: block;
  outline: none !important;
  cursor: pointer;
}

.atcb-link:focus ~ ul,
.atcb-link:active ~ ul,
.atcb-list:hover {
  visibility: visible;
}

.atcb-list {
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  width: 170px;
  z-index: 900;
}

.atcb-list,
.atcb-item {
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
}

.atcb-item {
  float: none;
  text-align: left;
  padding: 0 10px;
}

.atcb-item-link {
  text-decoration: none;
  outline: none;
  display: block;
}

.atcb-item.hover,
.atcb-item:hover {
  position: relative;
  z-index: 900;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.pagination {
  margin-top: 3vh;
  padding-top: 3vh;
  padding-bottom: 2vh;
  border-top: 1px solid #ddd;
  text-align: center;
}
.pagination .pagination_numbers {
  display: inline-block;
}
.pagination a {
  color: #AA9157;
}

.freeform-pages {
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
}
.freeform-pages li {
  margin: 0 10px 0 0;
}

.freeform-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -15px;
  flex-wrap: wrap;
}
.freeform-row .freeform-column {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 10px 0;
  margin: 0 15px;
  box-sizing: border-box;
  max-width: 100%;
}
.freeform-row .freeform-column div {
  max-width: 96%;
}
.freeform-row .freeform-column label {
  display: block;
  padding-bottom: 0.5em;
}
.freeform-row .freeform-column .freeform-label {
  font-weight: bold;
  padding-bottom: 0.5em;
  font-size: 1.1em;
}
.freeform-row .freeform-column .freeform-label.freeform-required:after {
  content: "*";
  margin-left: 5px;
  color: red;
}
.freeform-row .freeform-column .freeform-input {
  width: 100%;
  display: block;
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
}
.freeform-row .freeform-column .freeform-input[type=checkbox], .freeform-row .freeform-column .freeform-input[type=radio] {
  width: auto;
  display: inline;
  margin-right: 5px;
  vertical-align: top;
}
.freeform-row .freeform-column .freeform-input[type=text], .freeform-row .freeform-column .freeform-input[type=email], .freeform-row .freeform-column .freeform-input[type=password] {
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.5em;
}
.freeform-row .freeform-column select.freeform-input {
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.5em;
}
.freeform-row .freeform-column textarea.freeform-input {
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.5em;
}
.freeform-row .freeform-column .freeform-input-only-label {
  font-weight: normal;
}
.freeform-row .freeform-column .freeform-input-only-label > .freeform-input {
  display: inline-block;
  width: auto;
  margin-right: 5px;
}
.freeform-row .freeform-column .freeform-errors {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}
.freeform-row .freeform-column .freeform-errors > li {
  color: red;
}
.freeform-row .freeform-column .freeform-instructions {
  margin: 0 0 1em;
  font-style: italic;
}
.freeform-row .freeform-column.freeform-column-content-align-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.freeform-row .freeform-column.freeform-column-content-align-left > button:not(:first-of-type) {
  margin-left: 5px;
}
.freeform-row .freeform-column.freeform-column-content-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.freeform-row .freeform-column.freeform-column-content-align-center > button:not(:first-of-type) {
  margin-left: 5px;
}
.freeform-row .freeform-column.freeform-column-content-align-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.freeform-row .freeform-column.freeform-column-content-align-right > button:not(:first-of-type) {
  margin-left: 5px;
}
.freeform-row .freeform-column.freeform-column-content-align-spread {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.freeform-row .freeform-column.freeform-column-content-align-spread > button:not(:first-of-type) {
  margin-left: 5px;
}

.freeform-form-has-errors {
  padding: 1.75em 2em;
  margin-bottom: 2em;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
}

form fieldset {
  border: 1px solid #ddd;
  display: block;
  padding: 1.5em 2em 1.75em;
  margin-bottom: 2em;
  background: #fff;
}
form fieldset legend {
  background: #AA9157;
  color: #fff;
  margin-left: -1em;
  padding: 0.7em 1em 0.8em;
}

.freeform-hidden {
  display: none;
}

.side-image-block-wrap {
  margin-bottom: 5vh;
}
.side-image-block-wrap .side-image-block {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  margin-bottom: 5vh;
}
.side-image-block-wrap .side-image-block .image-block {
  width: 100%;
  height: 40vh;
}
.side-image-block-wrap .side-image-block .content {
  padding: 3%;
}
.side-image-block-wrap .side-image-block p {
  line-height: 1.3em;
}
@media only screen and (min-width:40.0625em) {
  .side-image-block-wrap .side-image-block {
    display: flex;
    align-items: stretch;
  }
  .side-image-block-wrap .side-image-block .image-block {
    width: 50%;
    height: auto;
    flex: 3;
  }
  .side-image-block-wrap .side-image-block .content {
    flex: 4;
  }
}

.feature-row-wrap {
  margin: 7vh 0;
}
@media only screen and (min-width:40.0625em) {
  .feature-row-wrap {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
@media only screen and (min-width:64.0625em) {
  .feature-row-wrap {
    flex-flow: row;
  }
}
.feature-row-wrap .feature-row {
  width: calc(100% - 10px);
  margin: 5px;
  overflow: hidden;
  background-color: #277DDE;
}
@media only screen and (min-width:40.0625em) {
  .feature-row-wrap .feature-row {
    width: 48%;
    display: flex;
    flex-direction: column;
  }
  .feature-row-wrap .feature-row.single {
    width: 100%;
  }
}
@media only screen and (min-width:64.0625em) {
  .feature-row-wrap .feature-row {
    width: 100%;
    margin: 0 3px;
  }
}
.feature-row-wrap .feature-row .image-block {
  width: 100%;
  height: 200px;
  display: block;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.feature-row-wrap .feature-row .feature-link {
  display: block;
  color: #fff;
  font-size: 1.5em;
  text-transform: uppercase;
  font-family: "PT Sans Narrow", sans-serif;
  text-align: center;
}
@media only screen and (min-width:40.0625em) {
  .feature-row-wrap .feature-row .feature-link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.feature-row-wrap .feature-row .feature-action {
  padding: 20px;
  background-color: #277DDE;
  position: relative;
  z-index: 2;
  transition: all 0.5s;
  flex: 1;
}
.feature-row-wrap .feature-row:hover .image-block,
.feature-row-wrap .feature-row:focus .image-block {
  transform: scale(1.1);
}
.feature-row-wrap .feature-row:hover .feature-action,
.feature-row-wrap .feature-row:focus .feature-action {
  background-color: #1c64b6;
}

.seating-types-wrap {
  margin: 4vh 0 7vh;
}
@media only screen and (min-width:40.0625em) {
  .seating-types-wrap {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
  }
}
@media only screen and (min-width:64.0625em) {
  .seating-types-wrap {
    flex-flow: row;
  }
}
.seating-types-wrap .seating-type {
  width: calc(100% - 10px);
  margin: 5px;
  overflow: hidden;
  background-color: #fff;
}
@media only screen and (min-width:40.0625em) {
  .seating-types-wrap .seating-type {
    width: 48%;
    display: flex;
    flex-direction: column;
  }
  .seating-types-wrap .seating-type.single {
    width: 100%;
  }
}
@media only screen and (min-width:64.0625em) {
  .seating-types-wrap .seating-type {
    width: 100%;
    margin: 0 3px;
  }
}
.seating-types-wrap .seating-type .content {
  padding: 2vh;
}
.seating-types-wrap .seating-type .gold-title {
  margin-bottom: 0;
  font-size: 1.625em;
  line-height: 1.2em;
}
.seating-types-wrap .seating-type .sections {
  font-family: "Crimson Text", serif;
  font-weight: 700;
}

.feature-row-wrap .fancy-feature {
  position: relative;
  background-color: transparent;
}
.feature-row-wrap .fancy-feature::after {
  clear: both;
  content: "";
  display: table;
}
@media only screen and (min-width:40.0625em) {
  .feature-row-wrap .fancy-feature {
    width: 48%;
    display: inline;
  }
}
.feature-row-wrap .fancy-feature .image-block {
  height: 100%;
  position: absolute;
}
.feature-row-wrap .fancy-feature .feature-action {
  max-width: 500px;
  margin: 15vh 3vh 7vh 0;
  text-align: center;
  color: #fff;
}
.feature-row-wrap .fancy-feature.gold .feature-action {
  background-color: #AA9157;
}
.feature-row-wrap .fancy-feature.blue .feature-action {
  background-color: #277DDE;
}
.feature-row-wrap .fancy-feature h3 {
  color: #fff;
  font-weight: 700;
  font-size: 1.875em;
  margin-bottom: 0;
}
.feature-row-wrap .fancy-feature .btn, .feature-row-wrap .fancy-feature .home_slider .Wallop-list .Wallop-item .bookingLink, .home_slider .Wallop-list .Wallop-item .feature-row-wrap .fancy-feature .bookingLink {
  position: absolute;
  right: -20px;
}
.feature-row-wrap .fancy-feature .icon {
  margin-right: 0;
}

.feature-row-wrap .fancy-feature:hover,
.feature-row-wrap .fancy-feature:focus {
  background-color: transparent;
}

.feature-row-wrap .fancy-feature.feature-home {
  overflow: visible;
  margin-bottom: 60px;
}
.feature-row-wrap .fancy-feature.feature-home:hover .image-block, .feature-row-wrap .fancy-feature.feature-home:focus .image-block {
  transform: none;
}
.feature-row-wrap .fancy-feature.feature-home.blue {
  background-color: #277DDE;
}
.feature-row-wrap .fancy-feature.feature-home.gold {
  background-color: #AA9157;
}
.feature-row-wrap .fancy-feature.feature-home.no-margin {
  margin-bottom: 0;
}
.feature-row-wrap .fancy-feature.feature-home .image-block {
  height: 350px;
  position: relative;
}
.feature-row-wrap .fancy-feature.feature-home .feature-action {
  max-width: 100%;
  margin: 0;
}
.feature-row-wrap .fancy-feature.feature-home .btn, .feature-row-wrap .fancy-feature.feature-home .home_slider .Wallop-list .Wallop-item .bookingLink, .home_slider .Wallop-list .Wallop-item .feature-row-wrap .fancy-feature.feature-home .bookingLink {
  right: 20px;
  bottom: -20px;
  z-index: 2;
}

.seatingChart {
  width: 100%;
  padding: 6vh 2vh;
  margin-bottom: 8vh;
  background-color: #fff;
  text-align: center;
}

.mapWrapper {
  display: flex;
  flex-direction: column;
}
.mapWrapper .googleMap {
  margin-bottom: 3vh;
}
.mapWrapper iframe {
  width: 100%;
}
.mapWrapper h5 {
  font-weight: 500;
}
@media only screen and (min-width:40.0625em) {
  .mapWrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .mapWrapper .googleMap {
    width: 49%;
  }
  .mapWrapper .entryBody {
    width: 49%;
    margin-right: 0;
  }
}

.dark-bg {
  background-image: linear-gradient(-180deg, #414141 1%, #000000 100%);
}

.basic-background-image {
  position: fixed;
  height: auto;
  width: 100%;
  top: 0;
}
@media only screen and (min-width:64.0625em) {
  .basic-background-image {
    max-height: 100vh;
    width: auto;
  }
}

.basic {
  margin: 0 3% 300px;
  padding: 2% 6%;
  position: relative;
  top: 200px;
  z-index: 100;
  background-color: #F3F3F3;
  max-width: 900px;
}
@media only screen and (min-width:64.0625em) {
  .basic {
    width: 100%;
    top: 50px;
    margin-bottom: 100px;
    float: right;
  }
}
.basic .breadcrumbs {
  margin-bottom: 4vh;
}
.basic .feature-row-wrap .feature-row .image-block {
  height: 30vh;
}

.full-width {
  margin-top: 5vh;
}
.full-width .breadcrumbs {
  margin-bottom: 5vh;
}
.full-width .introductionText {
  max-width: none;
}
.full-width .entryBody {
  max-width: none;
}

.partners-wrapper {
  margin: 0 0 7vh;
}
@media only screen and (min-width:40.0625em) {
  .partners-wrapper {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}
.partners-wrapper .partner {
  width: calc(100% - 10px);
  margin: 5px 5px 10px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width:40.0625em) {
  .partners-wrapper .partner {
    width: 48%;
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width:64.0625em) {
  .partners-wrapper .partner {
    width: calc(33% - 20px);
    margin: 0 10px 25px;
  }
}
.partners-wrapper .partner .logo-wrapper {
  padding: 2rem 4rem;
  border-bottom: 1px solid #ddd;
  position: relative;
  height: 220px;
  width: 100%;
}
.partners-wrapper .partner img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: center;
}
.partners-wrapper .partner .content {
  padding: 2rem;
}
.partners-wrapper .partner .content p:last-of-type {
  margin-bottom: 0;
}
.partners-wrapper .partner .link a {
  display: block;
  width: 100%;
  padding: 18px 20px;
  background-color: #277DDE;
  color: #fff;
  text-align: center;
  font-size: 1em;
}
.partners-wrapper .partner .link a:hover {
  background-color: #1c64b6;
}

.page_banner--event {
  color: #fff;
  flex-direction: column;
  flex-wrap: wrap;
}
@media only screen and (min-width: 53.125em) {
  .page_banner--event {
    flex-direction: row;
  }
}
.page_banner--event .page_banner__content,
.page_banner--event .page_banner__image {
  width: 100%;
}
@media only screen and (min-width: 53.125em) {
  .page_banner--event .page_banner__content,
  .page_banner--event .page_banner__image {
    width: 50%;
  }
}
.page_banner--event .page_banner__content {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.page_banner--event .page_banner__content > div:not(.event_banner__footer, .event_banner__date) {
  padding: 0 60px;
  margin-bottom: 60px;
}
.page_banner--event .page_banner__content > div.event_banner__footer {
  padding: 20px 30px;
  margin-bottom: 0;
}
.page_banner--event .page_banner__content > div.event_banner__date {
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 0;
}

.no-phones-banner {
  width: 100%;
  padding: 30px;
  background-color: #277DDE;
  color: white;
  font-size: 1.2em;
}
.no-phones-banner a {
  color: white;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 10px;
}
.no-phones-banner .icon {
  width: 24px;
  height: 24px;
}

#no-phones {
  padding-top: 1em;
  padding-bottom: 1em;
}
#no-phones .no-phones-lead {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;
  gap: 10px;
}
#no-phones .no-phones-lead .icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
#no-phones .no-phones-lead::before, #no-phones .no-phones-lead::after {
  content: "";
  border-top: 1px solid #A6A6A6;
  height: 1px;
  width: 100%;
}

.event_banner__date {
  font-family: "PT Sans Narrow", sans-serif;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: auto;
  padding: 30px 30px 60px;
}
.event_banner__date p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}
.event_banner__date p:first-of-type {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 8px;
}
.event_banner__date p:last-of-type {
  font-size: 1.4375em;
}
.event_banner__date p .icon {
  font-size: 18px;
  color: #A6A6A6;
  margin-right: 10px;
}
.event_banner__date a {
  color: #AA9157;
  font-size: 1.125em;
  padding: 10px 0;
}

.event_banner__copy {
  padding: 0 30px;
}

.event_banner__pre_title, .event_banner__post_title {
  color: #A6A6A6;
  font-style: italic;
  font-size: 1.125em;
  margin-bottom: 0;
}

.event_banner__post_title {
  color: #fff;
  font-style: normal;
}

.event_banner__title {
  font-family: "PT Sans Narrow", sans-serif;
  color: #277DDE;
  font-size: 3.25em;
  line-height: 1;
  margin-bottom: 0.1923076923em;
}

.event_banner__doors {
  color: #A6A6A6;
}

.event_banner__footer {
  margin-top: auto;
  background-color: #303030;
  color: #fff;
  padding: 20px 30px;
  font-size: 1.125em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.event_banner__footer .icon {
  font-size: 26px;
  margin-right: 8px;
  color: #A6A6A6;
}
.event_banner__footer > p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.2;
  margin-right: auto;
}
.event_banner__footer > p span {
  display: block;
  font-style: italic;
  font-weight: 400;
}
.event_banner__footer .event_banner__ticket_btn {
  margin: 1em 0 1em 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
.event_banner__footer .event_banner__ticket_btn .btn, .event_banner__footer .event_banner__ticket_btn .home_slider .Wallop-list .Wallop-item .bookingLink, .home_slider .Wallop-list .Wallop-item .event_banner__footer .event_banner__ticket_btn .bookingLink {
  display: block;
}
.event_banner__footer .event_banner__ticket_btn p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
  font-style: italic;
  font-size: 0.875em;
  font-weight: normal;
}
.event_banner__footer .event_banner__ticket_btn .btn + p, .event_banner__footer .event_banner__ticket_btn .home_slider .Wallop-list .Wallop-item .bookingLink + p, .home_slider .Wallop-list .Wallop-item .event_banner__footer .event_banner__ticket_btn .bookingLink + p {
  margin-top: 0.5em;
}

.press_article {
  margin-bottom: 7vh;
}
.press_article h3 {
  margin-bottom: 5px;
}
.press_article h3 a {
  color: #AA9157;
}
.press_article .post_date {
  display: block;
  color: #A6A6A6;
  margin-bottom: 15px;
}

.press_article_full h1 {
  margin-bottom: 5px;
}
.press_article_full .post_date {
  display: block;
  color: #A6A6A6;
  margin-bottom: 15px;
}

.mapLegend {
  margin-bottom: 5vh;
}
.mapLegend .subHeading, .mapLegend .mapWrapper h5, .mapWrapper .mapLegend h5 {
  margin-bottom: 4vh;
}
.mapLegend ul {
  padding: 0;
}
@media only screen and (min-width:40.0625em) {
  .mapLegend ul {
    column-count: 3;
    column-gap: 2vh;
  }
}
.mapLegend ul li {
  list-style-type: none;
  margin-bottom: 3vh;
  text-transform: uppercase;
}
.mapLegend ul li svg {
  margin-right: 8px;
  width: 28px;
  height: 28px;
}

.todaysevent__body {
  padding-bottom: 2em;
}
@media only screen and (min-width:64.0625em) {
  .todaysevent__body {
    display: flex;
  }
}
@media only screen and (min-width:64.0625em) {
  .todaysevent__body .todaysevent__column {
    width: 49%;
  }
}
@media only screen and (min-width:64.0625em) {
  .todaysevent__body .todaysevent__column:last-child {
    padding-left: 2%;
  }
}

.todaysevent__body-heading {
  font-size: 2.5em;
}

.todaysevent__banner.no-event {
  padding-top: 6em;
  padding-bottom: 6em;
  text-align: center;
  width: 100%;
}
.todaysevent__banner.no-event h1 {
  font-size: 4em;
}
.todaysevent__banner.no-event p {
  font-size: 1.5em;
}
.todaysevent__banner.no-event a {
  color: #AA9157;
}

.todaysevent__banner .events_listings {
  padding: 0 2em 0;
  margin-bottom: 0;
}
.todaysevent__banner .events_listings .events_listings__event {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
@media only screen and (min-width:40.0625em) {
  .todaysevent__banner .events_listings .events_listings__event {
    margin-left: 1% !important;
    margin-right: 1% !important;
    width: 48%;
  }
}
@media only screen and (min-width:64.0625em) {
  .todaysevent__banner .events_listings .events_listings__event {
    margin-left: 1% !important;
    margin-right: 1% !important;
    width: 23%;
  }
}
.todaysevent__banner .events_listings .events_listings__event h2, .todaysevent__banner .events_listings .events_listings__event p {
  text-align: left;
}
.todaysevent__banner .events_listings .events_listings__event .events_listings__post_title, .todaysevent__banner .events_listings .events_listings__event .events_listings__pre_title {
  font-size: 0.85em;
}

.event_announcement .events_listings__schedule_specials .no-phones-notice {
  padding-top: 1em;
  color: white;
  text-transform: none;
}
.event_announcement .events_listings__schedule_specials .no-phones-notice a {
  display: flex;
  align-items: center;
  color: white;
  text-transform: none;
  gap: 10px;
}
.event_announcement .events_listings__schedule_specials .no-phones-notice a > .icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.depart-maps img {
  display: inline-block;
  padding: 0;
  margin: 0 0 1em;
  border: 5px solid white !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 47%;
  vertical-align: middle;
}

.depart-maps img:first-child {
  margin-right: 2%;
}

.event_announcement {
  background-color: #0d2f55;
  box-shadow: inset 0 0 80px #1c1c1c;
  color: #ccc;
}
.event_announcement .container {
  padding: 4vh 3vw 3vh;
  transition: opacity 3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  max-width: 1200px;
}
.event_announcement .welcome_text {
  font-family: "PT Sans Narrow", sans-serif;
  text-transform: uppercase;
  color: #AA9157;
  font-weight: 500;
  text-align: center;
  font-size: 2em;
}
.event_announcement .welcome_smalltext {
  text-align: center;
}
.event_announcement h3 {
  font-size: 20px;
  margin-bottom: 0;
}
.event_announcement p {
  line-height: 1.3em;
  font-size: 0.9em;
}
@media only screen and (min-width:40.0625em) {
  .event_announcement .flex_wrapper {
    display: flex;
  }
}
.event_announcement .events_listings__event {
  align-self: flex-start;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
@media only screen and (min-width:40.0625em) {
  .event_announcement .events_listings__event {
    width: 33%;
    margin-right: 2%;
  }
}
@media only screen and (min-width:64.0625em) {
  .event_announcement .events_listings__event {
    width: 25%;
  }
}
@media only screen and (min-width:40.0625em) {
  .event_announcement .events_listings__schedule_specials {
    flex: 2;
    margin-right: 2%;
  }
}
.event_announcement .events_listings__schedule_specials a {
  margin-top: 5px;
  display: inline-block;
  font-family: "PT Sans Narrow", sans-serif;
  text-transform: uppercase;
  color: #AA9157;
}
.event_announcement .events_listings__details {
  margin: 0;
  padding: 0;
}
@media only screen and (min-width:40.0625em) {
  .event_announcement .events_listings__details {
    flex: 3;
  }
}
.event_announcement .events_listings__details .lawn_seating {
  display: flex;
}
.event_announcement .events_listings__details .lawn_seating > div {
  flex: 1;
}
.event_announcement .events_listings__details .lawn_seating > .image {
  max-width: 250px;
  margin-left: 0.5em;
}
.event_announcement .events_listings__details .lawn_seating > .image img {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.event_announcement .events_listings__details li {
  list-style-type: none;
}
.event_announcement .events_listings__details a {
  margin-top: 5px;
  display: inline-block;
  font-family: "PT Sans Narrow", sans-serif;
  text-transform: uppercase;
  color: #AA9157;
}
.event_announcement .close {
  color: #fff;
  display: inline-block;
  position: absolute;
  right: 3vw;
  top: 2vh;
}
.event_announcement .close svg {
  color: #fff;
  display: inline-block;
  font-size: 24px;
}

.slideIn-enter-active,
.slideIn-leave-active {
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.slideIn-enter-active .container,
.slideIn-leave-active .container {
  opacity: 0;
}

.slideIn-enter-to, .slideIn-leave {
  max-height: 1000px;
}
@media only screen and (max-width: 40em) {
  .slideIn-enter-to, .slideIn-leave {
    max-height: 1500px;
  }
}
.slideIn-enter-to .container, .slideIn-leave .container {
  opacity: 1;
}

.slideIn-leave-to,
.slideIn-enter {
  max-height: 0;
}
.slideIn-leave-to .container,
.slideIn-enter .container {
  opacity: 0;
}

.container.home {
  max-width: 1440px;
}

.feature-home.fancy-feature h3 {
  font-size: 1.75rem;
}

.home_slider .Wallop-list {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 50vh;
  min-height: 300px;
}
.home_slider .Wallop-list .Wallop-item {
  right: 0;
  bottom: 0;
  width: 100%;
  align-self: stretch;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center center;
}
.home_slider .Wallop-list .Wallop-item .events_listings__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home_slider .Wallop-list .Wallop-item .events_listings__content .events_listings__title,
.home_slider .Wallop-list .Wallop-item .events_listings__content .events_listings__pre_title,
.home_slider .Wallop-list .Wallop-item .events_listings__content .events_listings__post_title {
  margin: 0;
}
.home_slider .Wallop-list .Wallop-item .events_listings__content .events_listing__datetime {
  margin: 0;
  color: #AA9157;
  font-family: "PT Sans Narrow", sans-serif;
}
.home_slider .Wallop-list .Wallop-item .events_listings__content.offseason {
  padding: 18px;
}
.home_slider .Wallop-list .Wallop-item .events_listings__content.offseason .events_listings__title {
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.25rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (min-width:40.0625em) {
  .home_slider .Wallop-list .Wallop-item .events_listings__content.offseason {
    padding: 40px;
  }
  .home_slider .Wallop-list .Wallop-item .events_listings__content.offseason .events_listings__title {
    font-size: 1.4375em;
  }
}
.home_slider .Wallop-list .Wallop-item .bookingLink {
  margin: 5px;
}
.home_slider .Wallop-list .events_listings__content {
  background-color: rgba(0, 0, 0, 0.7);
}
.home_slider .Wallop-pagination {
  background-color: #303030;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-x: scroll;
  overflow-y: hidden;
}
.home_slider .Wallop-pagination .Wallop-dot {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 150px;
  padding: 0;
  background-color: transparent;
  transition: background-color 0.3s ease;
  outline: 0;
  border-right: 1px solid #EEEEEE;
}
.home_slider .Wallop-pagination .Wallop-dot--current {
  background-color: #000;
}
.home_slider .Wallop-pagination .events_listings__img h3 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1px;
  padding: 0 10px;
  height: 134px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0;
}
.home_slider .Wallop-pagination .events_listings__img img {
  width: 100%;
}
.home_slider .Wallop-pagination .content {
  width: 100%;
  padding: 1vh;
  text-align: left;
  align-self: center;
}
.home_slider .Wallop-pagination .content h4 {
  font-family: "Crimson Text", serif;
  font-size: 1.125em;
  font-weight: 500;
  color: #AA9157;
  margin-bottom: 4px;
}
.home_slider .Wallop-pagination .content h2 {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: #fff;
}
@media only screen and (min-width:40.0625em) {
  .home_slider .Wallop-list {
    height: 50vh;
    min-height: 500px;
  }
}
@media only screen and (min-width:64.0625em) {
  .home_slider {
    display: flex;
    flex-direction: row;
    height: 50vw;
    max-height: 800px;
  }
  .home_slider .Wallop-list {
    width: 72%;
    height: 100%;
    min-height: auto;
  }
  .home_slider .Wallop-list .events_listings__title {
    font-size: 2.5em;
  }
  .home_slider .Wallop-list .events_listing__datetime {
    font-size: 1.25em;
  }
  .home_slider .Wallop-pagination {
    width: 28%;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .home_slider .Wallop-pagination .Wallop-dot {
    border-right: 0;
    border-bottom: 1px solid #EEEEEE;
    max-height: 200px;
  }
  .home_slider .Wallop-pagination .Wallop-dot > div {
    display: flex;
    flex-direction: row;
  }
  .home_slider .Wallop-pagination .Wallop-dot .content {
    width: 55%;
  }
  .home_slider .Wallop-pagination .Wallop-dot .events_listings__img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.concertNews {
  margin: 7vh 0;
}
@media only screen and (min-width:40.0625em) {
  .concertNews {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    gap: 14px;
    padding: 0 10px;
  }
}
.concertNews .newsItem {
  width: 100%;
  overflow: hidden;
  background-color: #277DDE;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media only screen and (min-width:40.0625em) {
  .concertNews .newsItem {
    flex-basis: 440px;
  }
}
.concertNews .newsItem .play-btn {
  position: absolute;
  z-index: 3;
  width: 80px;
  height: 200px;
  left: 50%;
  margin-left: -40px;
  color: white;
}
.concertNews .newsItem .play-btn svg {
  width: 100%;
  height: 200px;
  margin: 0 auto;
  text-align: center;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
}
.concertNews a, .concertNews .image-block {
  display: block;
  width: 100%;
  height: 100%;
}
.concertNews a {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.concertNews .image-block {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.5s;
}
.concertNews .content {
  flex: 1;
  position: relative;
  padding: 1vh 2vh;
  bottom: 0;
  z-index: 101;
  margin-top: 200px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 0.5s;
  min-height: 130px;
}
.concertNews .content h6, .concertNews .content h3 {
  color: #fff;
}
.concertNews .content h6 {
  margin-bottom: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: italic;
  font-weight: 100;
  font-size: 1.125em;
}
.concertNews .content h3 {
  font-size: 2.125em;
  margin-bottom: 0;
  text-transform: none;
}
.concertNews a:hover,
.concertNews a:focus {
  cursor: pointer;
}
.concertNews a:hover .image-block,
.concertNews a:focus .image-block {
  transform: scale(1.1);
}
.concertNews a:hover .content,
.concertNews a:focus .content {
  background-color: rgba(170, 145, 87, 0.9);
}

.home-press-row {
  width: calc(100% - 10px);
  margin: 5px;
  background-color: #fff;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
@media only screen and (max-width: 40em) {
  .home-press-row {
    margin-top: 4vh;
  }
}
@media only screen and (min-width:40.0625em) {
  .home-press-row {
    width: calc(50% - 10px);
  }
}
@media only screen and (min-width:64.0625em) {
  .home-press-row {
    width: 50%;
    margin: 0 10px;
  }
}
.home-press-row .title-bar p {
  width: 50%;
  float: left;
  font-weight: 700;
}
.home-press-row .title-bar p:last-child {
  text-align: right;
}
.home-press-row .content {
  padding: 3vh 4vh 1.5vh;
  text-align: center;
}
.home-press-row .content h2 a {
  font-size: 1.375em;
  letter-spacing: -0.01em;
  line-height: 1.2em;
  color: #AA9157;
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 100;
}
.home-press-row .content p {
  font-size: 1em;
  font-family: "Crimson Text", serif;
  font-weight: 400;
  margin-bottom: 0.5em;
}
.home-press-row .content b {
  font-weight: 400;
}
.home-press-row .link {
  margin-bottom: 3vh;
  margin-top: auto;
  text-align: center;
}
.home-press-row .link a {
  display: inline-block;
}
.home-press-row .link a svg {
  margin-top: -5px;
}