/**
 * AddToCalendar Base CSS
 * http://addtocalendar.com
 */

/* Base */

.addtocalendar var{
    display: none;
}

.addtocalendar {
    position: relative;
    display: inline-block;
    background: transparent!important;
}

.atcb-link {
    display: block;
    outline: none!important;
    cursor: pointer;
}

.atcb-link:focus~ul,
.atcb-link:active~ul,
.atcb-list:hover{
    visibility:visible;
}

.atcb-list {
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    width: 170px;
    z-index: 900;
}

.atcb-list,
.atcb-item
{
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
}

.atcb-item {
    float: none;
    text-align: left;
    padding: 0 10px;
}

.atcb-item-link
{
    text-decoration: none;
    outline: none;
    display: block;
}

.atcb-item.hover,
.atcb-item:hover {
    position: relative;
    z-index: 900;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

