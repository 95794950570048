//
// Default Page Block Styles
//

//
// Side Image block
// Image on left (full height of content), content on right
//

.side-image-block-wrap {
  margin-bottom: 5vh;

  .side-image-block {
    background-color: #fff;
    border: 1px solid #E0E0E0;
    margin-bottom: 5vh;

    .image-block {
      width: 100%;
      height: 40vh;
    }

    .content { padding: 3%; }

    p { line-height: 1.3em; }

    // Large Styles
    @media #{$medium-up} {
      display: flex;
      align-items: stretch;

      .image-block {
        width: 50%;
        height: auto;
        flex: 3;
      }
      .content { flex: 4; }
    }
  }
}


// Feature Row (blue bottom link boxes)

.feature-row-wrap {
  margin: 7vh 0;

  @media #{$medium-up} {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media #{$large-up} {
    flex-flow: row;
  }

  .feature-row {
    width: calc(100% - 10px);
    margin: 5px;
    overflow: hidden;
    background-color: $blue;

    @media #{$medium-up} {
      width: 48%;
      display: flex;
      flex-direction: column;

      &.single {
          width: 100%;
      }
    }

    @media #{$large-up} {
      width: 100%;
      margin: 0 3px;
    }

    .image-block {
      width: 100%;
      height: 200px;
      display: block;
      transition: all .5s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }

    .feature-link { 
      display: block;
      color: #fff;
      font-size: em-calc(24px);
      text-transform: uppercase;
      @include pt-sans-narrow;
      text-align: center;

      @media #{$medium-up} {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .feature-action {
      padding: 20px;
      background-color: $blue;
      position: relative;
      z-index: 2;
      transition: all .5s;
      flex: 1;
    }
  }

  // Hover States
  .feature-row:hover,
  .feature-row:focus {

    .image-block {
      transform: scale(1.1);
    }

    .feature-action { background-color: darken($blue, 10%); }
  }
}

// Seating Types on Seating Chart
.seating-types-wrap {
  margin: 4vh 0 7vh;

  @media #{$medium-up} {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
  }
  @media #{$large-up} { flex-flow: row; }

  .seating-type {
    width: calc(100% - 10px);
    margin: 5px;
    overflow: hidden;
    background-color: #fff;

    @media #{$medium-up} {
      width: 48%;
      display: flex;
      flex-direction: column;

      &.single { width: 100%; }
    }

    @media #{$large-up} {
      width: 100%;
      margin: 0 3px;
    }

    .content { padding: 2vh; }

    .gold-title {
      margin-bottom: 0;
      font-size: em-calc(26px);
      line-height: 1.2em;
    }

    .sections {
      @include crimson-text;
      font-weight: 700;
    }
  }
}


// Fancy Feature Blocks to link to other sections
.feature-row-wrap .fancy-feature {
  position: relative;
  background-color: transparent;
  @include clearfix;

  @media #{$medium-up} {
    width: 48%;
    display: inline;
  }

  .image-block {
    height: 100%;
    position: absolute;
  }

  .feature-action {
    max-width: 500px;
    margin: 15vh 3vh 7vh 0;
    text-align: center;
    color: #fff;
  }
  
  &.gold .feature-action {
    background-color: $gold;

    // a { color: $gold; }
    // a:hover { background-color: $blue; color: #fff; }
  }
  &.blue .feature-action {
    background-color: $blue;

    // a { color: $blue; }
    // a:hover { background-color: $gold; color: #fff; }
  }

  h3 {
    color: #fff;
    font-weight: 700;
    font-size: em-calc(30px);
    margin-bottom: 0;
  }

  .btn {
    position: absolute;
    right: -20px;
  }

  .icon { margin-right: 0; }
}

//Fancy Feature Hover
.feature-row-wrap .fancy-feature:hover,
.feature-row-wrap .fancy-feature:focus {
  background-color: transparent;
}


// Fancy Feature Blocks — Tweaks for home page
.feature-row-wrap .fancy-feature.feature-home {
  overflow: visible;
  margin-bottom: 60px;
  &:hover, &:focus {
    .image-block {
      transform: none;
    }
  }
  &.blue {
    background-color: $blue;
  }
  &.gold {
    background-color: $gold;
  }
  &.no-margin {
    margin-bottom: 0;
  }

  .image-block {
    height: 350px;
    position: relative;
  }
  .feature-action {
    max-width: 100%;
    margin: 0;
  }
  .btn {
    right: 20px;
    bottom: -20px;
    z-index:2;
  }
}


//
//
// Seating Chart Content
//

.seatingChart {
  width: 100%;
  padding: 6vh 2vh;
  margin-bottom: 8vh;
  background-color: #fff;
  text-align: center;
}


//
//
// Plan Your Visit Landing Page
//(Map Area and Body Content end up in columns)
.mapWrapper {
  display: flex;
  flex-direction: column;

  .googleMap { margin-bottom: 3vh; }
  iframe { width: 100%; }
  h5 { @extend .subHeading; font-weight: 500; }

  @media #{$medium-up} {
    flex-direction: row;
    justify-content: space-between;
    
    .googleMap {
      width: 49%;
    }

    .entryBody {
      width: 49%;
      margin-right: 0;
    }
  }

}