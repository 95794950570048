//
// Press Section Styles

.press_article {
  margin-bottom: 7vh;

  h3 { margin-bottom: 5px; }
  h3 a { color: $gold; }

  .post_date {
    display: block;
    color: $grey;
    margin-bottom: 15px;
  }
}

.press_article_full {
  h1 { margin-bottom: 5px; }
  .post_date {
    display: block;
    color: $grey;
    margin-bottom: 15px;
  }
}